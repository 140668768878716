import {FILTER_TYPES} from 'app/components/Table/tableConstants';
import {RootState} from 'app/store';
import {doesValuePassFilter, getRewardStatus} from 'app/utils/helpers';
import parseISO from 'date-fns/parseISO';
import {createSelector} from 'reselect';

const getPartners = (state: RootState) => (state.campaign.campaign || {}).partners;
const getPartnersTableSort = (state: RootState) => state.campaignDetailsPage.partnerTableSort;
const getRewards = (state: RootState) => state.campaign.campaign.rewards;
const getRewardsTableSort = (state: RootState) => state.campaignDetailsPage.rewardsTableSort;
const getRewardsTablePage = (state: RootState) => state.campaignDetailsPage.rewardsTablePage;
const getRewardsTableFilters = (state: RootState) => state.campaignDetailsPage.rewardsTableFilters;
const getRewardsRowsPerPage = (state: RootState) => state.campaignDetailsPage.rewardsRowsPerPage;

const getMetrics = (state: RootState) => state.campaign.metrics;
const getMetricsTableSort = (state: RootState) => state.campaignDetailsPage.metricsTableSort;
const getMetricsTablePage = (state: RootState) => state.campaignDetailsPage.metricsTablePage;
const getMetricsTableFilters = (state: RootState) => state.campaignDetailsPage.metricsTableFilters;
const getMetricsRowsPerPage = (state: RootState) => state.campaignDetailsPage.metricsRowsPerPage;

export const getRewardsTableData = createSelector([getPartners, getRewards], (partners, rewards) => {
    const rewardsTableData = rewards.map(
        ({
             start_at,
             end_at,
             daily_limit,
             reward_id,
             reward_name,
             partner_id,
             reward_probability,
             target_variant,
             win_count,
             win_count_today,
             win_max,
             reward_value,
             target_countries,
             is_enabled,
         }: any) => ({
            status: getRewardStatus(start_at, end_at,daily_limit,win_count_today,is_enabled),
            reward_id,
            start_at,
            end_at,
            reward_name,
            partner_name: (partners.find((p: any) => p.partner_id === partner_id) || {}).name,
            reward_probability,
            reward_value,
            win_count: +win_count,
            win_count_today: win_count_today,
            win_max: +win_max,
            target_variant,
            target_countries,
        })
    );
    return rewardsTableData;
});

export const getMetricsTableData = createSelector([getMetrics], (metrics) =>
    metrics.map((metricTableEntry: any) => ({
        ...metricTableEntry,
        created_at: parseISO(metricTableEntry.created_at),
    }))
);

const sortFunc = (tableData: any, tableSort: any) => {
    const {field, direction} = tableSort;
    if (field && direction) {
        tableData = [...tableData].sort((a: any, b: any) => {
            const [aNormalized, bNormalized] =
                typeof a[field] === 'string' ? [a[field]?.toLowerCase(), b[field]?.toLowerCase()] : [a[field], b[field]];
            const sortDir = a[field] instanceof Date ? -1 : 1;
            const compareResult =
                aNormalized > bNormalized || aNormalized === null || aNormalized === undefined ? sortDir : -sortDir;
            return direction === 'DESC' ? compareResult : -compareResult;
        });
    }
    return [...tableData];
};

const filterFunc = (tableData: any, filters: any) =>
    tableData.filter((row: any) =>
        Object.entries(filters).every(([field, filter]: [string, any]) => {
            let res = true;
            if (filter.value) {
                if (filter.type === FILTER_TYPES.TEXT) {
                    res = doesValuePassFilter(row[field], filter.value);
                }
                if (filter.type === FILTER_TYPES.SELECT) {
                    res =
                        row[field] &&
                        (filter.value.length === 0 ||
                            filter.value.some((filterValue: string) => doesValuePassFilter(row[field], filterValue)));
                }
            }
            return res;
        })
    );

const pageFunc = (rewardsTableData: any, page: number, rowsPerPage: any) => {
    return rowsPerPage > 0
        ? rewardsTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : rewardsTableData;
};

export const getRewardsTableDataFiltered = createSelector([getRewardsTableData, getRewardsTableFilters], filterFunc);
export const getRewardsTableDataSorted = createSelector([getRewardsTableDataFiltered, getRewardsTableSort], sortFunc);
export const getPartnersTableDataSorted = createSelector([getPartners, getPartnersTableSort], sortFunc);

export const getRewardsTableDataSortedPaged = createSelector(
    [getRewardsTableDataSorted, getRewardsTablePage, getRewardsRowsPerPage],
    pageFunc
);

export const getMetricsTableDataFiltered = createSelector([getMetricsTableData, getMetricsTableFilters], filterFunc);
export const getMetricsTableDataSorted = createSelector([getMetricsTableDataFiltered, getMetricsTableSort], sortFunc);

export const getMetricsTableDataSortedPaged = createSelector(
    [getMetricsTableDataSorted, getMetricsTablePage, getMetricsRowsPerPage],
    pageFunc
);

export const getRewardsTableTotalRows = createSelector([getRewardsTableDataFiltered], (rewards) => rewards.length);
export const getMetricsTableTotalRows = createSelector([getMetricsTableDataFiltered], (metrics) => metrics.length);
