import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styles from './CampaignManager.module.scss';
import CampaignsList from './components/CampaignsList/CampaignsList';
import EditCampaign from './components/EditCampaign/EditCampaign';
import classNames from 'classnames';
import { getCampaigns } from '../../state/campaigns/CampaignsSlice';
import { RootState } from 'app/store';
import Skeleton from 'app/components/Skeleton/Skeleton';

export default function CampaignManager(): React.ReactElement {
  const currentlyEditedCampaign = useSelector<RootState, any>((state) => state.campaigns.currentlyEditedCampaign);
  const isCampaignsLoading = useSelector<RootState, boolean>((state) => state.campaigns.isCampaignsLoading);
  const isEditLoading = useSelector<RootState, boolean>((state) => state.campaigns.isEditLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCampaigns());
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.campaignsListHolder}>
        <Skeleton isLoading={isCampaignsLoading}>
          <CampaignsList></CampaignsList>
        </Skeleton>
      </div>
      <div className={classNames(styles.editCampaignHolder, { [styles.collapsed]: !currentlyEditedCampaign })}>
        <Skeleton isLoading={isEditLoading}>
          <EditCampaign></EditCampaign>
        </Skeleton>
      </div>
    </div>
  );
}
