import React, { useState } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import styles from './ImageZoomPreview.module.scss';

const IMAGE_SIZES = {
  height: 200,
  width: 200,
};
const DEFAULT_PREVIEW_SIZES = { height: 900, width: 900 };
const DEFAULT_ZOOM_MAGNITUDE = 2;
const ENLARGED_IMAGE_CONTAINER_DIMENSIONS = {
  width: '200%',
  height: '200%',
};
export interface Props {
  imageUrl: string;
  onError: () => void;
}

export default function ImageZoomPreview({ imageUrl, onError }: Props): React.ReactElement {
  const [previewSizes, setPreviewSizes] = useState(DEFAULT_PREVIEW_SIZES);

  return (
    <>
      <img
        onLoad={(e) => {
          const img = e.target as HTMLImageElement;
          const naturalHeight = img.naturalHeight || 1;
          const naturalWidth = img.naturalWidth || 1;
          let height = naturalHeight;
          let width = naturalWidth;
          while (
            height < IMAGE_SIZES.height * DEFAULT_ZOOM_MAGNITUDE ||
            width < IMAGE_SIZES.width * DEFAULT_ZOOM_MAGNITUDE
          ) {
            width *= DEFAULT_ZOOM_MAGNITUDE;
            height *= DEFAULT_ZOOM_MAGNITUDE;
          }
          setPreviewSizes({ height, width });
        }}
        src={imageUrl}
        className={styles.hidden}
      ></img>
      <ReactImageMagnify
        {...{
          smallImage: {
            isFluidWidth: false,
            width: IMAGE_SIZES.width,
            height: IMAGE_SIZES.height,
            src: imageUrl,
            onError: () => onError,
          },
          imageStyle: {
            objectFit: 'contain',
          },
          largeImage: {
            src: imageUrl,
            width: previewSizes.width,
            height: previewSizes.height,
          },
          enlargedImageContainerClassName: styles.zoomContainer,
          enlargedImageContainerDimensions: ENLARGED_IMAGE_CONTAINER_DIMENSIONS,
        }}
      />
    </>
  );
}
