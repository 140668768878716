import React, {FC, memo, ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';

import {ReactComponent as ArrowDown} from 'static/icons/caret-down.svg';
import { ReactComponent as ModuleAppsIcon } from 'static/icons/xelacoreModules/Fabacus_Module_Icons_Reach.svg';
import styles from './DropdownMenu.module.scss';

interface Props {
    title: ReactNode;
    options: {
        label: ReactNode;
        onClick: () => void;
    }[];
    showModuleIcon?: boolean;
    hasArrow?: boolean;
    menuheader?: string | boolean;
    onToggle?: (isOpened: boolean) => void;
}

const DropdownMenuComponent: FC<Props> = ({title, showModuleIcon, options, hasArrow = true, menuheader = false, onToggle = noop}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const closeDropdown = (e: MouseEvent) => {
        if (titleRef.current && e && e.target && e.target === titleRef.current) {
            return;
        }
        setIsMenuOpen(false);
    };
    const titleRef = useRef(null);

    const onTitleClick = useCallback(() => {
        setIsMenuOpen((value) => !value);
    }, [setIsMenuOpen, onToggle]);

    useEffect(() => {
        onToggle(isMenuOpen);
    }, [isMenuOpen]);

    useEffect(() => {
        if (isMenuOpen) {
            document.addEventListener('click', closeDropdown);
        }
        return () => {
            document.removeEventListener('click', closeDropdown);
        };
    }, [isMenuOpen]);

    return (
        <div className={styles.root}>
            <div ref={titleRef} className={styles.menuTitle} onClick={onTitleClick}>
                {title}
                {!!showModuleIcon && (
                 <span className={styles.menuModuleTitle}>
                     <ModuleAppsIcon></ModuleAppsIcon>
                     <span>Reach</span>
                 </span>
                )
                }
                {hasArrow && (
                    <ArrowDown className={classNames(styles.arrowIcon, {[styles.inverted]: isMenuOpen})}></ArrowDown>
                )}
            </div>
            <div className={classNames(styles.optionsHolder, {[styles.open]: isMenuOpen})}>
                {menuheader && (
                    <h4 className={styles.dropdownHeader}>{menuheader}</h4>
                )}
                {options.map(({label, onClick}, i) => (
                    <div key={i} className={styles.option} onClick={onClick}>
                        {label}
                    </div>
                ))}
            </div>
        </div>
    );
};

export const DropdownMenu = memo(DropdownMenuComponent);
