import { nanoid } from '@reduxjs/toolkit';
import React from 'react';
import styles from './Checkbox.module.scss';

interface Props {
  children: React.ReactNode;
  checked: boolean;
  onChange: (e: any) => void;
}

export default function Checkbox({ children, checked, onChange }: Props): React.ReactElement {
  const checkboxId = nanoid();

  return (
    <div className={styles.root}>
      <div className={styles.checkboxContainer}>
        <input onChange={onChange} type="checkbox" id={checkboxId} className={styles.checkMarker} checked={checked} />
        <div className={styles.checkmark}></div>
      </div>
      <label className={styles.checkboxLabel} htmlFor={checkboxId}>
        {children}
      </label>
    </div>
  );
}
