import Table from 'app/components/Table/Table';
import moment from "moment-timezone";
import {
    getRewardsTableDataSortedPaged,
    getRewardsTableTotalRows,
} from 'pages/CampaignDetails/campaignDetailsSelectors';
import {CampaignProprieties} from "../../../../interfaces/campaignInterfaces";
import {getRewards, resetRewardFormData} from "../../../../state/campaign/campaignSlice";
import {
    setRewardsTablePage,
    setRewardsTableSort,
    setRewardsRowsPerPage,
    setRewardsTableFilters,
    resetRewardsTableFilters,
} from '../../../../state/ui/campaignDetails/campaignDetailsPageSlice';
import {rewardsTableHeaders} from '../../constants/rewardsTableConstants';
import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styles from './CampaignRewards.module.scss';
import {RootState} from 'app/store';
import {ReactComponent as EditIcon} from 'static/icons/edit.svg';
import {ReactComponent as CopyIcon} from 'static/icons/duplicate.svg';
import {useHistory} from 'react-router';
import {rewardTypes} from 'constants/rewardConstants';
import {REWARD_STATUSES, REWARDS_ROWS_NUMBERS} from 'app/constants/rewardConstants';
import Button from 'components/Button/Button';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import Skeleton from 'app/components/Skeleton/Skeleton';
import classNames from 'classnames';
import {CampaignDetailsTableEntry} from 'pages/CampaignDetails/interfaces/campaignDetails';
import Tooltip from 'components/Tooltip/Tooltip';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import {DATE_DISPLAY_FORMAT, DATE_TIME_FORMAT} from 'app/constants/dateConstants';
import {ReactComponent as RefreshIcon} from 'static/icons/refresh.svg';
import {Filter} from 'app/components/Table/tableInterfaces';

const MAX_DISPLAYED_COUNTRIES = 4;

const getCountriesCol = (target_countries: string[]): string => {
    if (!target_countries) {
        return '';
    }
    const otherCountriesNumber = target_countries.length - MAX_DISPLAYED_COUNTRIES;
    const countriesString = target_countries.slice(0, MAX_DISPLAYED_COUNTRIES).join(', ');
    return otherCountriesNumber > 0 ? `${countriesString} and ${otherCountriesNumber} others` : countriesString;
};

const getAvailablePercentage = (winCount: number, winMaxCount: number) => {
    return Math.round((1 - winCount / winMaxCount) * 100);
};

export default function CampaignRewards(): React.ReactElement {
    const dispatch = useDispatch();
    const history = useHistory();
    const rewards = useSelector<RootState, CampaignDetailsTableEntry[]>(getRewardsTableDataSortedPaged);
    const partners = useSelector<RootState, any[]>((state) => (state.campaign.campaign || {}).partners);
    const isRewardsLoading = useSelector<RootState, boolean>((state) => state.campaign.isRewardsLoading);
    const campaignId = useSelector<RootState, string>((state) => state.campaign.campaign.campaign_id);
    const rewardsTableSort = useSelector<RootState, any>((state) => state.campaignDetailsPage.rewardsTableSort);
    const rewardsTableFilters = useSelector<RootState, { [key: string]: Filter }>(
        (state) => state.campaignDetailsPage.rewardsTableFilters
    );
    const campaignProperties = useSelector<RootState, CampaignProprieties>((state) => state.campaign.campaignProperties);
    const selectedTimezone = campaignProperties && campaignProperties.default_tz ? campaignProperties.default_tz : '+00:00';
    const rowsPerPage = useSelector<RootState, number>((state) => state.campaignDetailsPage.rewardsRowsPerPage);
    const rewardsTablePage = useSelector<RootState, number>((state) => state.campaignDetailsPage.rewardsTablePage);
    const rewardsTableTotalRows = useSelector<RootState, number>(getRewardsTableTotalRows);

    const refreshTable = useCallback(() => {
        dispatch(getRewards(campaignId));
    }, [campaignId]);

    const clearFilters = () => {
        dispatch(resetRewardsTableFilters());
    };

    const editReward = (id: string) => history.push(`${history.location.pathname}/edit/${id}/editRewards`);
    const rows = rewards.map(
        ({
             status,
             start_at,
             end_at,
             daily_limit,
             reward_id,
             reward_name,
             partner_name,
             reward_value,
             reward_probability,
             win_count,
             win_count_today,
             win_max,
             target_variant,
             target_countries,
         }) => [
            <EditIcon className={styles.editIcon} onClick={() => editReward(reward_id)}></EditIcon>,
            <Tooltip
                position="right"
                tooltip={
                    start_at || end_at ? (
                        <div className={styles.dateTootip}>
                            {start_at &&
                                <div>Starts: {moment(parseISO(start_at)).zone(selectedTimezone).format('ddd DD/MMM/yyyy, hh:mm Z')}</div>}
                            {end_at &&
                                <div>Ends: {moment(parseISO(end_at)).zone(selectedTimezone).format('ddd DD/MMM/yyyy, hh:mm Z')}</div>}
                        </div>
                    ) : null
                }>
                <span
                    className={classNames({
                        [styles.rewardActive]: status === REWARD_STATUSES.ACTIVE,
                        [styles.rewardExpired]: status === REWARD_STATUSES.EXPIRED,
                        [styles.rewardPending]: status === REWARD_STATUSES.PENDING,
                        [styles.rewardPending]: status === REWARD_STATUSES.PAUSED,
                        [styles.rewardDisabled]: status === REWARD_STATUSES.DISABLED,
                    })}
                >
                    {status}
                </span>
            </Tooltip>,
            reward_name,
            partner_name,
            rewardTypes.find((rt) => rt.value === reward_value)?.label,
            reward_probability,
            win_count,
            win_count_today,
            <div className={styles.percentageColumn}>
                <span className={styles.winMax}>{win_max}</span>

                <ProgressBar percentage={getAvailablePercentage(+win_count, +win_max)}></ProgressBar>
            </div>,
            target_variant,
            getCountriesCol(target_countries),
            <div className={styles.rewardIdCol}>
                <span>{reward_id}</span>
                <CopyIcon onClick={()=> {
                    navigator.clipboard.writeText(reward_id);
                }} className={styles.copyIcon}></CopyIcon>
            </div>,
        ]
    );

    return (
        <div className={styles.root}>
            <div className={styles.controlsHolder}>
                <Button onClick={clearFilters} disabled={isRewardsLoading}>
                    Clear Filters
                </Button>
                <Button onClick={refreshTable} iconRight={<RefreshIcon height={16}></RefreshIcon>}
                        disabled={isRewardsLoading}>
                    Refresh Table
                </Button>
            </div>

            <Skeleton isLoading={isRewardsLoading}>
                {!isRewardsLoading && (
                    <Table
                        sort={rewardsTableSort}
                        filters={rewardsTableFilters}
                        page={rewardsTablePage}
                        onRowsNumberSelected={(value: number) => dispatch(setRewardsRowsPerPage(value))}
                        rowsPerPage={rowsPerPage}
                        rowsNumbers={REWARDS_ROWS_NUMBERS}
                        rows={rows}
                        totalRows={rewardsTableTotalRows}
                        headers={rewardsTableHeaders}
                        onPageSelected={(p: number) => dispatch(setRewardsTablePage(p))}
                        onSort={(field: any) => dispatch(setRewardsTableSort(field))}
                        onFilter={(filter) => dispatch(setRewardsTableFilters(filter))}
                    ></Table>
                )}

                {partners.length > 0 && (
                    <div className={styles.roundButtonHolder}>
                        <button className={styles.roundButton} onClick={() => {
                            dispatch(resetRewardFormData());
                            history.push(`/campaign/${campaignId}/createReward`);
                        }}>
                            <Tooltip position="left" tooltip="Add Campaign Reward">
                                <span>+</span>
                            </Tooltip>
                        </button>
                    </div>
                )}

            </Skeleton>
        </div>
    );
}
