import axios from 'axios';
import cookie from 'js-cookie';
import config from '../../env-config';

import { serializeObject } from '../utils/helpers';
import {
  SCOPE,
  GRANT_TYPE,
  USER_TOKEN_COOKIE,
  USER_REFRESH_TOKEN_COOKIE,
  ORG_ID_COOKIE,
} from '../constants/oauthConstants';
import { logout } from '../utils/helpers';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const api = axios.create({
  baseURL: config.services.api,
  headers: { Accept: 'Application/Json' },
});

api.interceptors.request.use(
  (config) => {
    const token = cookie.get(USER_TOKEN_COOKIE);
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = cookie.get(USER_REFRESH_TOKEN_COOKIE);
      const organisationId = cookie.get(ORG_ID_COOKIE);

      if (!refreshToken || !organisationId) {
        logout();
      }

      return new Promise((resolve, reject) => {
        const formData = serializeObject({
          refresh_token: refreshToken,
          grant_type: GRANT_TYPE,
          client_id: config.client_id,
          redirect_uri: config.redirect_uri,
          scope: SCOPE,
          organisation_id: organisationId,
        });

        axios
          .post(`${config.services.api}/oauth/token`, formData, {
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
          })
          .then(({ data }) => {
            cookie.set(USER_TOKEN_COOKIE, data.access_token, {
              domain: config.appDomain,
              secure: true,
              sameSite: 'strict',
            });
            cookie.set(USER_REFRESH_TOKEN_COOKIE, data.refresh_token, {
              domain: config.appDomain,
              secure: true,
              sameSite: 'strict',
            });
            originalRequest.headers['Authorization'] = 'Bearer ' + data.token;
            processQueue(null, data.token);
            resolve(api.request(originalRequest));
          })
          .catch((err) => {
            logout();
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

export default api;
