import React from 'react';
import styles from './TableRowsNumber.module.scss';
import Dropdown from '../../../../../../../components/Dropdown/Dropdown';

interface Props {
    rowsPerPage: number;
    onRowsNumberSelected: any;
    rowsNumbers: any[];
}

function TableRowsNumber({ rowsPerPage, onRowsNumberSelected, rowsNumbers }: Props): React.ReactElement {
    return (
        <div className={styles.root}>
            <span className={styles.showLabel}>
                Show:
            </span>

            <Dropdown
                className={styles.numberSelect}
                value={{
                  value: rowsPerPage,
                  label: rowsNumbers.find(({ value }) => value === rowsPerPage)!.label,
                }}
                options={rowsNumbers}
                onChange={({ value }) => onRowsNumberSelected(value)}
                isSearchable={false}
            ></Dropdown>
        </div>
    );
}

export default TableRowsNumber;
