import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
    resetPartnerTableSort,
    resetRewardsTableFilters,
    resetRewardsTableSort
} from "../../../../state/ui/campaignDetails/campaignDetailsPageSlice";
import { ReactComponent as IconEdit } from '../../../../static/icons/edit.svg';

import styles from './CampaignCard.module.scss';
import { setCurrentlyEditedCampaign, setError } from '../../../../state/campaigns/CampaignsSlice';
import { useHistory } from 'react-router-dom';

export default function CampaignCard({ campaign }: { campaign: any }): React.ReactElement {
  const dispatch = useDispatch();
  const [cardImage, setCardImage] = useState('');
  const history = useHistory();

  useEffect(() => {
    setCardImage(campaign.campaign_image_url);
  }, [campaign.campaign_image_url]);

  const navigateToEdit = () => {
      dispatch(resetPartnerTableSort());
      dispatch(resetRewardsTableSort());
      dispatch(resetRewardsTableFilters());
      history.push(`campaign/${campaign.campaign_id}`);
  }

  return (
    <div onClick={navigateToEdit} className={styles.root}>
      <div className={styles.campaignInfo}>
        <div className={styles.campaignImageHolder}>
          <img
            src={cardImage || undefined}
            className={styles.campaignImage}
            onError={() => setCardImage('img/broken-image-placeholder.jfif')}
          ></img>
        </div>
        <h2 className={styles.campaignName}>{campaign.campaign_name}</h2>
      </div>
      <button
        className={styles.editButton}
        onClick={(e) => {
          e.stopPropagation();
          dispatch(setCurrentlyEditedCampaign(campaign));
          dispatch(setError(''));
        }}
      >
        <IconEdit className={styles.iconEdit}></IconEdit>
      </button>
    </div>
  );
}

CampaignCard.propTypes = {
  campaign: PropTypes.object,
};
