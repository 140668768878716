import {RootState} from 'app/store';
import classNames from 'classnames';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import {useHistory} from "react-router-dom";
import {components} from "react-select";
import {
    sendCodesData,
    setIsRewardCreated,
    setRewardsVoucherCodesFieldValue
} from '../../../../state/campaign/campaignSlice';
import React, {useCallback, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {useDispatch, useSelector} from 'react-redux';

import styles from './VoucherCodesDropzone.module.scss';
import {parseVoucherCodesFromCsv} from 'app/utils/helpers';
import {cloneDeep} from "lodash";

export default function VoucherCodesDropzone(): React.ReactElement {
    const values = useSelector<RootState, { [key: string]: number | string[] | boolean | null }>(
        (state) => state.campaign.rewardVoucherCodes.values
    );
    const [file, setFile] = useState<File | null>(null);
    const dispatch = useDispatch();
    const isRewardCreated = useSelector<RootState, boolean>((state) => state.campaign.isRewardCreated);
    const rewardId = useSelector<RootState, string | null>((state) => state.campaign.rewardId);
    const history = useHistory();
   const [preservedData, setPreservedData] = useState(values);

    const onDrop = useCallback(async (acceptedFiles) => {
        if (acceptedFiles.length < 1) {
            return;
        }
        const currentFile = acceptedFiles[0];
        setFile(currentFile);
        const codes = await parseVoucherCodesFromCsv(currentFile);
        dispatch(setRewardsVoucherCodesFieldValue({field: 'codes', value: codes}));
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, multiple: false, accept: '.csv'});

    useEffect(() => {
        if (isRewardCreated) {
            dispatch(setIsRewardCreated(false));
            const pathArray = location.pathname.split('/');

            if (rewardId) {
                if (pathArray[pathArray.length - 3] === 'edit') {
                    const pathArray = location.pathname.split('/');
                    pathArray[pathArray.length - 2] = rewardId;
                    history.push(pathArray.join('/'));
                } else {
                    history.push(`./edit/${rewardId}/editRewards`);
                }
            }
        }
    })

    const preserveCampaign = (key: string, value: string[] | number | null | boolean) => {
        const newData = cloneDeep(preservedData);
        newData[key] = value;
        setPreservedData(newData);
    }

    return (
        <form
            className={styles.root}
            onSubmit={(e) => {
                dispatch(sendCodesData({fileUploadCodes:true}));
                e.preventDefault();
            }}
        >
            <div
                {...getRootProps({
                    onClick: (event) => {
                        const button = (event.target as HTMLElement).closest('#chooseFileBtn');
                        if (!button) {
                            event.stopPropagation();
                        }
                    },
                })}
                className={classNames(styles.dndArea, {[styles.dragActive]: isDragActive})}
            >
                <h2 className={styles.heading}>{file === null ? 'Drag and drop your CSV file' : `File: ${file?.name}`}</h2>
                <p className={styles.text}>
                    Alternatively click the button below to browse
                    <br/>
                    your local files.
                </p>
                <Button type="action" size="large" className={styles.chooseFileButton} id="chooseFileBtn">
                    {file === null ? 'Choose file' : 'Choose another file'}
                </Button>
                <input {...getInputProps()} />
            </div>
            <div className={styles.inputHolder}>
                <p className={styles.label}>Times voucher codes can be used</p>
                <Input
                    value={values.win_max as number}
                    onChange={(e) => dispatch(setRewardsVoucherCodesFieldValue({
                        field: 'win_max',
                        value: +e.target.value
                    }))}
                    type="number"
                    min={1}
                    max={1000000000}
                    className={styles.smallInput}
                ></Input>
            </div>
            <div className={styles.checkboxInput}>
                <label>
                    <input type="checkbox"
                           defaultChecked={!!values.not_visible_in_email_upload}
                           onChange={(e) => {
                               dispatch(setRewardsVoucherCodesFieldValue({
                                   field: 'not_visible_in_email_upload',
                                   value: e.target.checked
                               }))
                               preserveCampaign('not_visible_in_email_upload', e.target.checked);
                           }}
                    />
                    <p className={styles.label}>Not visible in email</p>
                </label>
            </div>
            <Button disabled={file === null} submit size="large" type="action" className={styles.submit}>
                Add codes
            </Button>
        </form>
    );
}
