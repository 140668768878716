import React from 'react';
import styles from './Input.module.scss';
import classNames from 'classnames';

interface Props {
    error?: string | null;
    type?: string;
    name?: string;
    placeholder?: string;
    value: string | number;
    className?: string;
    autoComplete?: string;
    min?: number;
    max?: number;
    multiline?: boolean;
    required?: boolean;
    disabled?: boolean;
    disableErrorLabel?: boolean;
    forwardedRef?: React.ForwardedRef<HTMLInputElement | HTMLTextAreaElement>;
    onChange: (e: any) => void;
    onBlur?: (e: any) => void;
}

function Input({
                   name,
                   autoComplete = 'on',
                   type = 'text',
                   placeholder = '',
                   value,
                   onChange,
                   onBlur,
                   className,
                   min,
                   max,
                   forwardedRef,
                   disableErrorLabel = false,
                   required = false,
                   disabled = false,
                   multiline = false,
                   error,
               }: Props): React.ReactElement {
    return (
        <div className={classNames(styles.root)}>
            {multiline ? (
                <textarea
                    required={required}
                    disabled={disabled}
                    name={name}
                    ref={forwardedRef as React.ForwardedRef<HTMLTextAreaElement>}
                    className={classNames(styles.input, className, {[styles.hasError]: !!error})}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    dir="auto"
                ></textarea>
            ) : (
                <input
                    placeholder={placeholder}
                    required={required}
                    disabled={disabled}
                    autoComplete={autoComplete}
                    name={name}
                    ref={forwardedRef as React.ForwardedRef<HTMLInputElement>}
                    className={classNames(styles.input, className, {[styles.hasError]: !!error})}
                    type={type}
                    min={min}
                    max={max}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    dir="auto"
                ></input>
            )}
            {!!error && !disableErrorLabel && <p className={styles.errorMessage}>{error}</p>}
        </div>
    );
}

export default React.forwardRef((props: Props, ref: React.ForwardedRef<HTMLInputElement | HTMLTextAreaElement>) => {
    return <Input {...props} forwardedRef={ref}/>;
});
