import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import styles from './OrganisationSelect.module.scss';
import {setOrgId} from '../../features/auth/authSlice';
import {logout} from 'app/utils/helpers';
import Button from 'components/Button/Button';
import {RootState} from 'app/store';
import {ORGANISATION_TYPES} from 'app/constants/xelacoreConstants';
import {useHistory} from 'react-router-dom';
import {fetchToken} from '../../features/auth/authApi';
import {ORG_ID_COOKIE} from '../../app/constants/oauthConstants';
import cookie from 'js-cookie';

export default function OrganisationSelect(): React.ReactElement {
    const organisations = useSelector<RootState, any[]>((state) => state.auth.organisations);
    const dispatch = useDispatch();
    const history = useHistory();

    const selectOrganisation = (orgId: string) => {
        dispatch(setOrgId(orgId));
        setTimeout(() => {
            fetchToken().then(() => {
                history.push('/');
                window.location.reload();
            });
        }, 1)
    };

    const currentOrganisationId = cookie.get(ORG_ID_COOKIE);
    const licensors = organisations.filter(({type}) => type === ORGANISATION_TYPES.LICENSOR);
    if (licensors.length === 1 && (currentOrganisationId!==licensors[0].organisation_id)) {
        selectOrganisation(licensors[0].organisation_id)
    }

    return (
        <div className={styles.root}>
            <div className={styles.logoHolder}>
                <img className={styles.logoImg} src="/img/xelacore-logo.svg" alt="logo"/>
            </div>
            <h1 className={styles.heading}>Account Selection</h1>
            <h3 className={styles.subHeading}>Please select which account you want to access</h3>
            <div className={styles.orgList}>
                {licensors.map(({organisation_id, name, type}) => (
                    <div key={organisation_id} onClick={() => selectOrganisation(organisation_id)}
                         className={styles.orgCard}>
                        <p className={styles.orgName}>{name}</p>
                        <p className={styles.orgType}>{type}</p>
                    </div>
                ))}
            </div>
            <Button size="large" type="text" onClick={logout} className={styles.logoutButton}>
                Log Out
            </Button>
        </div>
    );
}
