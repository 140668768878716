import {cloneDeep, forEach} from "lodash";
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from 'app/store';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import {
    sendRewardData,
    setRewardsLanguageData,
    removeRewardsLanguage,
    setRewardsLanguageCurrentLanguage
} from '../../../../state/campaign/campaignSlice';
import {LanguageData} from 'interfaces/campaignInterfaces';
import styles from './RewardLanguageForm.module.scss';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import {ReactComponent as DeleteIcon} from 'static/icons/delete.svg';
import {ReactComponent as CheckIcon} from 'static/icons/checkmark.svg';

interface languageItem {
    label: string,
    value: string
}

interface Props {
    language: string;
    addedLanguagesOptions: languageItem[];
}

export default function RewardLanguageForm({language, addedLanguagesOptions}: Props): React.ReactElement {
    const languageData = useSelector<RootState, LanguageData>(
        (state) => state.campaign.rewardsLanguages.values[language]
    );
    const campaignEmailTemplate = useSelector<RootState,any>(
        (state) => state?.campaign?.campaign?.properties?.template?.email
    );

    const isCampaignLoading = useSelector<RootState, boolean>((state) => state.campaign.isCampaignLoading);
    const [preservedData, setPreservedData] = useState(languageData);

    const dispatch = useDispatch();
    const dispatchValueChange = (name: string, value: string) => {
        dispatch(
            setRewardsLanguageData({
                language,
                field: name,
                value,
            })
        );
    };

    const saveLanguageList = () => {
        forEach(preservedData,(value,key)=>{
            dispatchValueChange(key, value);
        })
        dispatch(sendRewardData())
    }
    const removeLanguageList = () => {
        const filteredLanguages = addedLanguagesOptions.filter(lang => lang.value !== language)
        dispatch(setRewardsLanguageCurrentLanguage(filteredLanguages[0].value));
        dispatch(removeRewardsLanguage(language));
        dispatch(sendRewardData());
    }

    const preserveLanguageData = (key:string,value:string)=>{
        const newData = cloneDeep(preservedData);
        newData[key]=value;
        setPreservedData(newData);
    }

    return (
        <form className={styles.root}
              onSubmit={(e)=>{
                  e.preventDefault();
                  saveLanguageList();
              }}>

            <div className={styles.imageBlock}>
                <ImageUpload
                    verticalLayout={true}
                    showPreview={true}
                    imageUrl={languageData?.reward_image_url}
                    resize={campaignEmailTemplate?.reward_image_url?.resize}
                    applyOnUrlChange={true}
                    onImageSubmit={(e) => {
                        preserveLanguageData('reward_image_url', e);
                        dispatchValueChange('reward_image_url', e);
                    }}
                ></ImageUpload>

                <ImageUpload
                    verticalLayout={true}
                    showPreview={true}
                    imageFieldLabel='Button Image URL'
                    imageUrl={languageData?.reward_link_image}
                    resize={campaignEmailTemplate?.reward_link_image.resize}
                    applyOnUrlChange={true}
                    onImageSubmit={(e) => {
                        preserveLanguageData('reward_link_image', e);
                        dispatchValueChange('reward_link_image', e);
                    }}
                ></ImageUpload>
            </div>

            <div className={styles.fieldsBlock}>
                <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>
                        Title <span className={styles.red}>*</span>
                    </label>
                    <Input
                        required
                        className={styles.formInput}
                        value={preservedData.reward_title}
                        name="reward_title"
                        disabled={isCampaignLoading}
                        onChange={(e) => {
                            preserveLanguageData('reward_title', e.target.value);
                        }}
                    ></Input>
                </div>

                <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>
                        Message <span className={styles.red}>*</span>
                    </label>
                    <Input
                        required
                        className={styles.formInput}
                        value={preservedData.reward_message}
                        name="reward_message"
                        disabled={isCampaignLoading}
                        onChange={(e) => {
                            preserveLanguageData('reward_message', e.target.value);
                        }}
                    ></Input>
                </div>

                <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>
                        Description <span className={styles.red}>*</span>
                    </label>
                    <Input
                        required
                        multiline={true}
                        className={styles.textarea}
                        value={preservedData.reward_description}
                        name="reward_description"
                        disabled={isCampaignLoading}
                        onChange={(e) => {
                            preserveLanguageData('reward_description', e.target.value);
                        }}
                    ></Input>
                </div>

                <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>Terms</label>
                    <Input
                        multiline={true}
                        className={styles.textarea}
                        value={preservedData.reward_terms}
                        name="reward_terms"
                        disabled={isCampaignLoading}
                        onChange={(e) => {
                            preserveLanguageData('reward_terms',e.target.value)
                        }}
                    ></Input>
                </div>

                <div className={styles.formInputContainer}>
                    <label className={styles.inputLabel}>URL Link</label>
                    <Input
                        className={styles.formInput}
                        value={preservedData.reward_link}
                        name="reward_link"
                        disabled={isCampaignLoading}
                        onChange={(e) => {
                            preserveLanguageData('reward_link', e.target.value);
                        }}
                    ></Input>
                </div>

                <div className={styles.inlineActions}>
                    <Button size="large"
                            type="action"
                            submit
                            disabled={isCampaignLoading}
                            iconLeft={<CheckIcon height={24}></CheckIcon>}
                            className={styles.submitButton}>
                        Apply changes
                    </Button>
                    {addedLanguagesOptions.length > 1 && (
                        <Button size="large"
                                type="danger"
                                disabled={isCampaignLoading}
                                className={styles.submitButton}
                                iconLeft={<DeleteIcon height={24}></DeleteIcon>}
                                onClick={() => removeLanguageList()}>
                            Remove Language
                        </Button>
                    )}
                </div>
            </div>

        </form>
    );
}
