import { DATE_DISPLAY_FORMAT } from 'app/constants/dateConstants';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styles from './Datepicker.module.scss';

interface Props {
  value: Date | null;
  name?: string;
  onChange: (value: Date) => void;
  maxDate?: Date | null;
  minDate?: Date | null;
  error?: string;
  disabled?: boolean;
}

export default function Datepicker({ value, onChange, name, error, maxDate, minDate,disabled }: Props): React.ReactElement {
  return (
    <div className={styles.root}>
      <DatePicker
        autoComplete="off"
        maxDate={maxDate}
        minDate={minDate}
        name={name}
        dateFormat={DATE_DISPLAY_FORMAT}
        selected={value}
        disabled={disabled}
        onChange={onChange}
      ></DatePicker>
      {!!error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
}
