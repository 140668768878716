import React, { useEffect, useState } from 'react';
import { RootState } from 'app/store';
import Input from 'components/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from "react-router-dom";
import {setIsRewardCreated, setRewardsVoucherCodesFieldValue} from "../../../../state/campaign/campaignSlice";
import styles from './PartnerPortal.module.scss';
import Button from 'components/Button/Button';
import { showNotification } from 'state/ui/notifications/notificationsSlice';

import find from 'lodash/find';
import toNumber from 'lodash/toNumber';

import {
  generateCodes
} from '../../../../api/campaignApi';

export default function PartnerPortal(): React.ReactElement {
  const [quantity, setQuantity] = useState('');
  const [notVisibleInEmail, setNotVisibleInEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [busy, setBusy] = useState(false);
  const dispatch = useDispatch();
  const isRewardCreated = useSelector<RootState, boolean>((state) => state.campaign.isRewardCreated);
  const rewardId = useSelector<RootState, string | null>((state) => state.campaign.rewardId);
  const history = useHistory();
  const values = useSelector<RootState, { [key: string]: number | string[] | null | boolean }>(
      (state) => state.campaign.rewardVoucherCodes.values
  );

  const campaign = useSelector<RootState, any | null>((state) => state.campaign)
  const reward = find(campaign && campaign.campaign && campaign.campaign.rewards, (r: any) => {
    return r.reward_id === campaign.rewardId;
  }) || {};
  const partner = find(campaign && campaign.campaign && campaign.campaign.partners, (p: any) => {
    return p.partner_id === reward.partner_id;
  }) || {};

  const handleChange = (value: string) => {
    setErrorMessage('');

    if(!!value && toNumber(value) < 1) {
      setErrorMessage('Number of codes should be greater than 0.');
      return;
    }

    if(!!value && toNumber(value) > 1000) {
      setErrorMessage('Number of codes should not be greater than 1000.');
      return;
    }

    setQuantity(value);
  }
  
  const generateCodesFunction = () => {
    const params = {
      campaign_id: reward.campaign_id || '',
      organisation_id: campaign.campaign.licensor_organisation_id || '',
      reward_id: reward.reward_id || '',
      reward_name: reward.reward_name || '',
      reward_title: reward.reward_attributes.en.reward_title || '',
      partner_id: reward.partner_id || '',
      partner_name: partner.name || '',
      quantity: quantity || '',
      not_visible_in_email: notVisibleInEmail || false
    }

    setBusy(true);

    generateCodes(params).then((response: any) => {
      dispatch(showNotification(`${quantity} codes were generated successfully!`));
      setErrorMessage('');
      setBusy(false);
    }, () => {
      setErrorMessage('Oops! Something went wrong. Please try again.')
      setBusy(false);
    })
  }


  useEffect(()=>{
    if (isRewardCreated) {
      dispatch(setIsRewardCreated(false));
      const pathArray = location.pathname.split('/');
      if (rewardId) {
        if (pathArray[pathArray.length - 3] === 'edit') {
          const pathArray = location.pathname.split('/');
          pathArray[pathArray.length - 2] = rewardId;
          history.push(pathArray.join('/'));
        } else {
          history.push(`./edit/${rewardId}/editRewards`);
        }
      }
    }
  })

  return (
    <form
      className={styles.root}
      onSubmit={(e) => {
        generateCodesFunction();
        e.preventDefault();
      }}
    >
      <div className={styles.form}>
        <div className={styles.inputHolderGrow}>
          <p className={styles.label}>Number of codes</p>
          <Input
            type="text"
            required
            value={quantity}
            onChange={(e) => handleChange(e.target.value.replace(/[^0-9]/g, ""))}
            className={styles.bigInput}
          ></Input>
        </div>
        <div className={styles.checkboxInput}>
          <label>
            <input type="checkbox"
                   defaultChecked={notVisibleInEmail}
                   onChange={(e) => {
                     setNotVisibleInEmail(e.target.checked)
                   }}
            />
            <p className={styles.label}>Not visible in email</p>
          </label>
        </div>
      </div>

      <Button submit 
              size="large" 
              type="action" 
              className={styles.submit}
              disabled={!!busy}
      >
        { !!busy && (<div className={styles.loader}></div>)}
        Generate codes
      </Button>

      { !!errorMessage && (
        <p className={styles.errorMessage}>{errorMessage}</p>
      )}
    </form>
  );
}
