import {RootState} from 'app/store';
import isEqual from 'lodash/isEqual';
import Dropdown from 'components/Dropdown/Dropdown';
import {useHistory} from "react-router-dom";
import Skeleton from "../../../../app/components/Skeleton/Skeleton";
import {
    addRewardsLanguage,
    setIsRewardCreated,
    setRewardsLanguageCurrentLanguage
} from '../../../../state/campaign/campaignSlice';
import {languages} from 'constants/rewardConstants';
import {LanguageData} from 'interfaces/campaignInterfaces';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import RewardLanguageForm from '../RewardLanguageForm/RewardLanguageForm';
import {ReactComponent as AddIcon} from '../../../../static/icons/add.svg';
import styles from './RewardLanguages.module.scss';
import Tabs from 'components/Tabs/Tabs';
import Tab from 'components/Tabs/Tab';

export default function RewardLanguages(): React.ReactElement {
    const [selectedAvailableLanguage, setSelectedAvailableLanguage] = useState(languages[0]);
    const dispatch = useDispatch();
    const isRewardCreated = useSelector<RootState, boolean>((state) => state.campaign.isRewardCreated);
    const rewardId = useSelector<RootState, string | null>((state) => state.campaign.rewardId);
    const history = useHistory();
    const rewardsLanguagesValues = useSelector<RootState, { [key: string]: LanguageData }>(
        (state) => state.campaign.rewardsLanguages.values,
        isEqual
    );

    const currentLanguage = useSelector<RootState, string>((state) => state.campaign.rewardsLanguages.currentLanguage);
    const isCampaignLoading = useSelector<RootState, boolean>((state) => state.campaign.isCampaignLoading) || false;

    const addedLanguagesOptions = Object.keys(rewardsLanguagesValues)
        .map((language) => {
            const languageLabel = languages.find(({value}) => value === language);
            // if(languageLabel){
            //
            // }
            return ({
                value: language,
                label: languageLabel ? languageLabel.label : '',
            })
        })
        .sort(({value}) => (value === 'en' ? -1 : 1));

    const availableLanguages = languages.filter(
        ({value}) => !Object.keys(rewardsLanguagesValues).some((language) => language === value)
    );

    const selectedLanguage = () => {
        const currentLanguageIndex = addedLanguagesOptions.findIndex(({value}) => currentLanguage === value);
        return currentLanguageIndex > -1 ? currentLanguageIndex : 0;
    }

    useEffect(() => {
        if (isRewardCreated) {
            dispatch(setIsRewardCreated(false));
            const pathArray = location.pathname.split('/');
            if (rewardId) {
                if (pathArray[pathArray.length - 3] === 'edit') {
                    const pathArray = location.pathname.split('/');
                    pathArray[pathArray.length - 2] = rewardId;
                    history.push(pathArray.join('/'));
                } else {
                    history.push(`./edit/${rewardId}/editRewards`);
                }
            }
        }

        if (
            Object.keys(rewardsLanguagesValues).some(
                (language) => language === selectedAvailableLanguage.value && availableLanguages.length > 0
            )
        ) {
            setSelectedAvailableLanguage(availableLanguages[0]);
        }
    });

    return (
        <Skeleton isLoading={isCampaignLoading}>
            <div className={styles.root}>
                {addedLanguagesOptions.length === 0 && <h1 className={styles.heading}>No languages added yet</h1>}
                {availableLanguages.length > 0 && (
                    <div className={styles.addLanguageContainer}>
                        <div>
                            <Dropdown
                                className={styles.languageSelect}
                                value={selectedAvailableLanguage}
                                options={availableLanguages}
                                onChange={(language) => setSelectedAvailableLanguage(language)}
                            ></Dropdown>
                        </div>
                        <button
                            onClick={() => {
                                const defaultImage = rewardsLanguagesValues && rewardsLanguagesValues['en'] ? rewardsLanguagesValues['en'].reward_image_url : null;
                                dispatch(addRewardsLanguage({
                                    reward_image_url: rewardsLanguagesValues && rewardsLanguagesValues[currentLanguage] ? rewardsLanguagesValues[currentLanguage].reward_image_url : defaultImage,
                                    newLanguage: selectedAvailableLanguage.value
                                }));
                            }}
                            className={styles.roundButton}
                        >
                            <AddIcon className={styles.addIcon}></AddIcon>
                        </button>
                        <span className={styles.label}>Add language</span>
                    </div>
                )}

                {addedLanguagesOptions.length > 0 && (
                    <Tabs
                        selectedIndex={selectedLanguage()}
                        onSelect={(i) => dispatch(setRewardsLanguageCurrentLanguage(addedLanguagesOptions[i].value))}
                    >
                        {addedLanguagesOptions.map(({label, value}) => (
                            <Tab name={label} key={value}>
                                <div className={styles.formHolder}>
                                    <RewardLanguageForm language={value}
                                                        addedLanguagesOptions={addedLanguagesOptions}></RewardLanguageForm>
                                </div>
                            </Tab>
                        ))}
                    </Tabs>
                )}
            </div>
        </Skeleton>
    );
}
