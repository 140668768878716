import React from 'react';
import styles from './EditCampaign.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';

import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import { editCampaign, setCurrentlyEditedCampaign, setError } from '../../../../state/campaigns/CampaignsSlice';
import { RootState } from 'app/store';
import { ReactComponent as CrossSmallIcon } from '../../../../static/icons/cross-small.svg';

export default function EditCampaign(): React.ReactElement {
  const currentlyEditedCampaign = useSelector<RootState, any>((state) => state.campaigns.currentlyEditedCampaign);
  const error = useSelector<RootState, any>((state) => state.campaigns.error);
  const dispatch = useDispatch();

  return (
    currentlyEditedCampaign && (
      <Formik
        enableReinitialize={true}
        initialValues={currentlyEditedCampaign}
        validate={(values) => {
          const errors: any = {};
          if (!values.campaign_name) {
            errors.campaign_name = 'Please, specify campaign name';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(editCampaign(values));
          setSubmitting(true);
        }}
      >
        {({ values, setFieldValue, setTouched, errors, dirty, handleChange, handleSubmit, touched }) => (
          <form className={styles.root} onSubmit={handleSubmit}>
            <CrossSmallIcon
              className={styles.closeIcon}
              onClick={() => dispatch(setCurrentlyEditedCampaign(null))}
            ></CrossSmallIcon>
            <div className={styles.headerSection}>
              <h3 className={styles.heading}>Edit Campaign</h3>
              <ImageUpload
                imageUrl={values.campaign_image_url}
                onImageSubmit={(e) => {
                  setTouched({ ...touched, campaign_image_url: true }, true);
                  setFieldValue('campaign_image_url', e);
                  dispatch(setError(''));
                }}
                error={touched.campaign_image_url ? errors.campaign_image_url || error : null}
              ></ImageUpload>
            </div>
            <div className={styles.formInputContainer}>
              <label className={styles.inputLabel}>Campaign Name</label>
              <Input
                className={styles.formInput}
                value={values.campaign_name}
                name="campaign_name"
                onChange={handleChange}
                error={touched.campaign_name ? (errors.campaign_name as string) : null}
              ></Input>
            </div>

            <Button submit disabled={!dirty} type="action" size="large" className={styles.submitButton}>
              Confirm Changes
            </Button>
          </form>
        )}
      </Formik>
    )
  );
}
