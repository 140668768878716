export const config = {
  services: {
    login: 'https://login.xelacore.io',
    api: 'https://api.live.xelacore.io',
    reach: 'https://reach.xelacore.io',
    account: 'https://account.xelacore.io',
    reg: 'https://fabacus.xelacore.io',
    plm: 'https://app.xelacore.com',
    cam: 'https://concepts.xelacore.io',
  },
  appDomain: '.xelacore.io',
  baseUrl: 'https://api.live.xelacore.io',
  client_id: 'e4bdcdd8-f69d-4b40-bf54-266410e64c08',
  redirect_uri: 'https://live.xelacore.io',
};

export default config;
