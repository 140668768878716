import stagingConfig from './config/env-configs/staging.config';
import testingConfig from './config/env-configs/testing.config';
import localConfig from './config/env-configs/local.config';
import developmentConfig from './config/env-configs/development.config';
import liveConfig from './config/env-configs/live.config';

declare let process: { env: { REACT_APP_STAGE: any } };

const configMap: any = {
  development: developmentConfig,
  staging: stagingConfig,
  testing: testingConfig,
  local: localConfig,
  live: liveConfig,
};

const config: any = configMap[process.env.REACT_APP_STAGE] || developmentConfig;
export default config;
