import React from 'react';
import styles from './ProgressBar.module.scss';
import classNames from 'classnames';

interface Props {
    percentage: number;
}

export default function ProgressBar({ percentage }: Props): React.ReactElement {
    return (
        <div
            className={classNames(
                    styles.progressBar,
                    {
                      [styles.red]: percentage < 20 ,
                      [styles.orange]: percentage >= 20 && percentage < 50,
                      [styles.green]: percentage >= 50,
                    }
                )}
        >
              <span
                  style={{ width: percentage + '%' }}>
              </span>
        </div>
    );
}
