import React, {useEffect, useState} from 'react';
import {flatCountries, groupedCountries} from 'app/constants/countries';
import {RootState} from 'app/store';
import moment from 'moment-timezone';
import Datepicker from 'components/Datepicker/Datepicker';
import Dropdown from 'components/Dropdown/Dropdown';
import Input from 'components/Input/Input';
import parseISO from 'date-fns/parseISO';
import {Formik} from 'formik';
import {useHistory} from "react-router-dom";
import Select, {components, GroupProps} from "react-select";
import Skeleton from "../../../../app/components/Skeleton/Skeleton";
import {CampaignProprieties} from "../../../../interfaces/campaignInterfaces";
import {
    sendRewardData,
    setIsRewardCreated,
    setRewardConditionsFieldValue
} from '../../../../state/campaign/campaignSlice';
import {useDispatch, useSelector} from 'react-redux';
import {difference, findIndex, intersection, toString, union} from 'lodash';
import {ReactComponent as CheckIcon} from "../../../../static/icons/checkmark.svg";
import {ReactComponent as CrossIcon} from "../../../../static/icons/cross-small.svg";
import {ReactComponent as AngleDown} from "../../../../static/icons/angle-small-down.svg";

import styles from './RewardConditions.module.scss';
import Button from 'components/Button/Button';

const OFFSETS = [
    '-12:00',
    '-11:00',
    '-10:00',
    '-09:30',
    '-08:00',
    '-07:00',
    '-06:00',
    '-05:00',
    '-04:00',
    '-03:30',
    '-03:00',
    '-02:00',
    '-01:00',
    '+00:00',
    '+01:00',
    '+02:00',
    '+03:00',
    '+03:30',
    '+04:00',
    '+04:30',
    '+05:00',
    '+05:30',
    '+05:45',
    '+06:00',
    '+06:30',
    '+07:00',
    '+08:00',
    '+08:45',
    '+09:00',
    '+09:30',
    '+10:00',
    '+10:30',
    '+11:00',
    '+12:00',
    '+12:45',
    '+13:00',
    '+14:00'
]

type CountriesArray = string[];

export default function RewardConditions(): React.ReactElement {
    const values = useSelector<RootState,
        { target_variant: string; daily_limit: string; target_countries: string[]; start_at: string | null; end_at: string | null }>((state) => state.campaign.rewardsConditions.values);
    const dispatch = useDispatch();
    const campaignProperties = useSelector<RootState, CampaignProprieties>((state) => state.campaign.campaignProperties);
    const selectedTimezone = campaignProperties && campaignProperties.default_tz ? campaignProperties.default_tz : '+00:00';
    const [startTime, setStartTime] = useState(values.start_at ? moment(values.start_at).zone(selectedTimezone).format('HH:mm') : '00:00');
    const [startZone, setStartZone] = useState(selectedTimezone);
    const [endTime, setEndTime] = useState(values.end_at ? moment(values.end_at).zone(selectedTimezone).format('HH:mm') : '00:00');
    const [endZone, setEndZone] = useState(selectedTimezone);
    const [selectedCountriesList, setSelectedCountriesList] = useState<CountriesArray>([]);
    const [countrySearched, setCountrySearched] = useState<string>('');
    const [expandCountryGroup, setExpandCountryGroup] = useState(99);
    const isRewardCreated = useSelector<RootState, boolean>((state) => state.campaign.isRewardCreated);
    const rewardId = useSelector<RootState, string | null>((state) => state.campaign.rewardId);
    const history = useHistory();
    const isCampaignLoading = useSelector<RootState, boolean>((state) => state.campaign.isCampaignLoading) || false;

    const dispatchValueChange = (name: string, value: string | string[] | null) => {
        return dispatch(
            setRewardConditionsFieldValue({
                field: name,
                value,
            })
        );
    }

    const handleStartTime = (value: string) => {
        setStartTime(value === '' ? '00:00' : value);
        dispatchValueChange('start_at', parseDate(values.start_at, value, startZone));
    };

    const handleEndTime = (value: string) => {
        setEndTime(value === '' ? '00:00' : value);
        dispatchValueChange('end_at', parseDate(values.end_at, value, endZone));
    };

    const handleStartZone = (value: string) => {
        setStartZone(value);
        dispatchValueChange('start_at', parseDate(values.start_at, startTime, value));
    };

    const handleEndZone = (value: string) => {
        setEndZone(value);
        dispatchValueChange('end_at', parseDate(values.end_at, endTime, value));
    };

    const parseDate = (value: string | null, time: string, zone: string) => {
        time = time === '' ? '00:00' : time;
        return moment(moment(value).format('yyyy-MM-DD') + 'T' + time + zone).toISOString()
    }

    const handleSelectedCountries = (countries: string[]) => {
        setSelectedCountriesList(countries);
        dispatchValueChange('target_countries', countries);
    }

    const handleCountriesSearch = (inputValue: string) => {
        setCountrySearched(inputValue);
    }

    const Option = (props: any) => {
        const parentIndex = findIndex(props.options, (item: any) => {
            const itemOptions = item.options.map((itemOption: any) => itemOption.value)
            return itemOptions.includes(props.data.value);
        });

        if (expandCountryGroup === parentIndex || countrySearched.length > 0) {
            return (
                <components.Option {...props}>
                    <label className={styles.selectGroupOptionItem}>
                        <input type="checkbox"
                               defaultChecked={props.isSelected}
                        />
                        <span>{props.label}</span>
                    </label>
                </components.Option>
            );
        } else {
            return null
        }
    };

    const MultiValueContainer = (props: any) => {
        return (
            <components.MultiValueContainer  {...props}>
                <div className={styles.selectCountriesPlaceholder}>
                    {values.target_countries && values.target_countries.length > 0 && (
                        <p>{values.target_countries.length} Target {values.target_countries.length > 1 ? 'Countries' : 'Country'} Selected</p>
                    )}
                </div>
            </components.MultiValueContainer>
        );
    };

    useEffect(() => {
        if (values && values.target_countries) {
            setSelectedCountriesList(values.target_countries)
        }

        if (isRewardCreated) {
            dispatch(setIsRewardCreated(false));
            const pathArray = location.pathname.split('/');

            if (rewardId) {
                if (pathArray[pathArray.length - 3] === 'edit') {
                    const pathArray = location.pathname.split('/');
                    pathArray[pathArray.length - 2] = rewardId;
                    history.push(pathArray.join('/'));
                } else {
                    history.push(`./edit/${rewardId}/editRewards`);
                }
            }
        }
    })


    return (
        <Skeleton isLoading={isCampaignLoading}>
            <Formik
                initialValues={values}
                onSubmit={() => {
                    dispatch(sendRewardData());
                }}
            >
                {({errors, setFieldValue, setTouched, handleChange, handleSubmit, touched}) => (
                    <form className={styles.root} onSubmit={handleSubmit}>
                        <div className={styles.formGroupContainer}>
                            <label className={styles.inputLabel}>
                                Starts on
                            </label>

                            <div className={styles.formGroupWrapper}>
                                <div className={styles.formInputContainer}>
                                    <div className={styles.inputHolder}>
                                        <Datepicker
                                            name="start_at"
                                            value={values.start_at ? parseISO(values.start_at) : null}
                                            maxDate={values.end_at ? parseISO(values.end_at) : null}
                                            disabled={isCampaignLoading}
                                            onChange={(value: Date) => {
                                                dispatchValueChange('start_at', value ? parseDate(toString(value), startTime, startZone) : null)
                                            }
                                            }
                                        ></Datepicker>
                                        <CrossIcon className={styles.deleteInputValue} onClick={() => {
                                            dispatchValueChange('start_at', null)
                                        }}></CrossIcon>
                                    </div>
                                </div>
                                <div className={styles.formInputContainer}>
                                    <div className={styles.inputHolder}>
                                        <div className='react-datepicker-wrapper'>
                                            <input
                                                type="time"
                                                onChange={(event) => handleStartTime(event.target.value)}
                                                value={startTime}
                                                name="start_at_time"
                                                disabled={isCampaignLoading}
                                                required/>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.formInputContainer}>
                                    <div className={styles.inputHolder}>
                                        <Dropdown
                                            options={OFFSETS.map((offset) => ({value: offset, label: 'UTC' + offset}))}
                                            className={styles.formInput}
                                            value={{
                                                label: 'UTC' + startZone,
                                                value: startZone
                                            }}
                                            name="startOffset"
                                            disabled={isCampaignLoading}
                                            onChange={({value}) => handleStartZone(value)}
                                        ></Dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.formGroupContainer}>
                            <label className={styles.inputLabel}>
                                Ends
                            </label>

                            <div className={styles.formGroupWrapper}>
                                <div className={styles.formInputContainer}>
                                    <div className={styles.inputHolder}>
                                        <Datepicker
                                            name="end_at"
                                            minDate={values.start_at ? parseISO(values.start_at) : null}
                                            value={values.end_at ? parseISO(values.end_at) : null}
                                            disabled={isCampaignLoading}
                                            onChange={(value: Date) => dispatchValueChange('end_at', value ? parseDate(toString(value), endTime, endZone) : null)}
                                        ></Datepicker>
                                        <CrossIcon className={styles.deleteInputValue} onClick={() => {
                                            dispatchValueChange('end_at', null)
                                        }}></CrossIcon>
                                    </div>
                                </div>
                                <div className={styles.formInputContainer}>
                                    <div className={styles.inputHolder}>
                                        <div className='react-datepicker-wrapper'>
                                            <input
                                                type="time"
                                                disabled={isCampaignLoading}
                                                onChange={(event) => handleEndTime(event.target.value)}
                                                value={endTime}
                                                name="end_at_time"
                                                required/>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.formInputContainer}>
                                    <div className={styles.inputHolder}>
                                        <Dropdown
                                            options={OFFSETS.map((offset) => ({value: offset, label: 'UTC' + offset}))}
                                            className={styles.formInput}
                                            value={{
                                                label: 'UTC' + endZone,
                                                value: endZone
                                            }}
                                            name="endOffset"
                                            disabled={isCampaignLoading}
                                            onChange={({value}) => handleEndZone(value)}
                                        ></Dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.formInputContainer}>
                            <label className={styles.inputLabel}>Daily Limit</label>
                            <div className={styles.inputHolder}>
                                <Input
                                    type="number"
                                    className={styles.formInput}
                                    value={values.daily_limit || ''}
                                    name="daily_limit"
                                    disabled={isCampaignLoading}
                                    onChange={(e) => {
                                        handleChange(e);
                                        dispatchValueChange('daily_limit', e.target.value);
                                    }}
                                    error={touched.daily_limit ? (errors.daily_limit as string) : null}
                                ></Input>
                            </div>
                        </div>

                        <div className={styles.formInputContainer}>
                            <label className={styles.inputLabel}>Variant</label>
                            <div className={styles.inputHolder}>
                                <Input
                                    className={styles.formInput}
                                    value={values.target_variant || ''}
                                    name="target_variant"
                                    disabled={isCampaignLoading}
                                    onChange={(e) => {
                                        handleChange(e);
                                        dispatchValueChange('target_variant', e.target.value);
                                    }}
                                    error={touched.target_variant ? (errors.target_variant as string) : null}
                                ></Input>
                            </div>
                        </div>

                        <div className={styles.formInputContainer}>
                            <label className={styles.inputLabel}>Countries</label>

                            <Select
                                className={styles.selectGroupWrapper}
                                isMulti={true}
                                isDisabled={isCampaignLoading}
                                placeholder={'Select Target Country'}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                value={(values.target_countries || []).map((v) => {
                                    const {value, label} = flatCountries.find((c) => c.value === v) || {
                                        value: '',
                                        label: ''
                                    };
                                    return {value, label};
                                })}
                                onChange={(options: any) => {
                                    const values = options.map((option: any) => option.value);
                                    setTouched({...touched, target_countries: true}, true);
                                    setFieldValue('target_countries', values);
                                    handleSelectedCountries(values);
                                }}
                                onInputChange={handleCountriesSearch}
                                options={groupedCountries.map((group, index) => {
                                    const groupCountries = group.options.map(country => country.value);
                                    const selectedCountries = intersection(groupCountries, values.target_countries);

                                    return ({
                                        ...group,
                                        label: (
                                            <div className={countrySearched.length ? styles.selectGroupWrap : ''}>
                                                {countrySearched.length < 1 && (
                                                    <div className={styles.selectGroupHead}>
                                                        <input type="checkbox"
                                                               checked={selectedCountries.length > 0}
                                                               onChange={(e) => {
                                                                   if (selectedCountries.length > 0) {
                                                                       handleSelectedCountries(difference(selectedCountriesList, groupCountries))
                                                                   } else {
                                                                       handleSelectedCountries(union(selectedCountriesList, groupCountries))
                                                                   }
                                                               }}
                                                        />
                                                        <span>
                                                        {group.label}
                                                    </span>
                                                        <div className={styles.selectGroupHeadAction}
                                                             onClick={() => {
                                                                 setExpandCountryGroup(expandCountryGroup === index ? 99 : index);
                                                             }}>
                                                <span className={styles.selectGroupOptionItemCounter}>
                                                    {selectedCountries.length} Target {selectedCountries.length > 1 ? 'Countries' : 'Country'} Selected
                                                </span>
                                                            <i className={expandCountryGroup === index ? styles.selectGroupHeadActionActive : styles.selectGroupHeadActionDefault}
                                                            ><AngleDown height={20}></AngleDown>
                                                            </i>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ),
                                        options: (group.options.map((option, index) => ({
                                                ...option
                                            })
                                        ))
                                    })
                                })}
                                components={{Option, MultiValueContainer}}
                            />
                        </div>
                        <div>
                            <Button submit type="action" size="large"
                                    className={styles.submitButton}
                                    disabled={isCampaignLoading}
                                    iconLeft={<CheckIcon height={20}></CheckIcon>}>
                                Confirm Changes
                            </Button>
                        </div>
                    </form>
                )}
            </Formik>
        </Skeleton>
    );
}
