import Checkbox from 'components/Checkbox/Checkbox';
import Input from 'components/Input/Input';
import React, { Ref, useCallback, useEffect, useRef } from 'react';
import { FILTER_TYPES } from '../../tableConstants';
import { Filter } from '../../tableInterfaces';
import styles from './ColumnFilter.module.scss';

interface Props {
  type?: string;
  filterOptions?: { value: string; label: string }[];
  filter: Partial<Filter>;
  position: { top: number; left: number };
  forwardedRef?: Ref<HTMLDivElement>;
  onChange: (value: any) => void;
}

function ColumnFilter({
  forwardedRef,
  type,
  position = { top: 0, left: 0 },
  filter = { value: [] },
  filterOptions = [],
  onChange,
}: Props): React.ReactElement {
  const rootRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleCheckboxChange = useCallback(
    (option: string, isChecked: boolean) =>
      onChange({
        value: isChecked
          ? [...(filter.value as string[]), option]
          : (filter.value as string[]).filter((v: string) => v !== option),
      }),
    [filter]
  );

  useEffect(() => {
    const input = inputRef.current;
    if (!input) {
      return;
    }
    input.focus();
    input.setSelectionRange(0, input.value.length);
  }, [inputRef]);

  return (
    <div ref={rootRef} className={styles.root} style={{ top: position.top, left: position.left }}>
      <div ref={forwardedRef} className={styles.panelHolder}>
        {type === FILTER_TYPES.TEXT && (
          <>
            <Input
              ref={inputRef}
              placeholder="Type here..."
              onChange={(e) => onChange({ value: e.target.value })}
              value={(filter.value as string) || ''}
            ></Input>
          </>
        )}
        {type === FILTER_TYPES.SELECT && (
          <div className={styles.listContainer}>
            {filterOptions.map(({ value, label }) => (
              <Checkbox
                key={value}
                onChange={(e) => handleCheckboxChange(value, e.target.checked)}
                checked={(filter.value as string[])?.some((v: string) => v === value)}
              >
                {label}
              </Checkbox>
            ))}
            <span className={styles.resetFiltersButton} onClick={() => onChange({ value: [] })}>
              Reset
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default React.forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  return <ColumnFilter {...props} forwardedRef={ref} />;
});
