export const config = {
  services: {
    login: 'https://login.staging.xelacore.io',
    api: 'https://api.staging.xelacore.io',
    reach: 'https://reach.staging.xelacore.io',
    account: 'https://account.staging.xelacore.io',
    reg: 'https://staging.xelacore.io',
    plm: 'https://staging.app.xelacore.com',
    cam: 'https://uat.concepts.xelacore.io',
  },
  appDomain: '.xelacore.io',
  baseUrl: 'https://api.staging.xelacore.io',
  client_id: '548a8f84-6f19-429a-9702-de81d2b67869',
  redirect_uri: 'https://staging.xelacore.io',
};

export default config;
