import React from 'react';
import Select from 'react-select';
import styles from './Dropdown.module.scss';
import classNames from 'classnames';
import {Option} from './dropdownConstants';

interface Props {
    error?: string | null;
    isMulti?: boolean;
    disabled?: boolean;
    closeMenuOnSelect?: boolean;
    isSearchable?: boolean,
    name?: string;
    value: Option | Option[];
    options: Option[];
    className?: string;
    onChange: (e: any) => void;
}

export default function Dropdown({
                                     name,
                                     value,
                                     options,
                                     onChange,
                                     className,
                                     error,
                                     closeMenuOnSelect = true,
                                     isMulti = false,
                                     disabled = false,
                                     isSearchable = true
                                 }: Props): React.ReactElement {

    return (
        <div className={styles.root}>
            <Select
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        text: '#27bac2',
                        primary25: '#efefef',
                        primary50: '#27bac2',
                        primary75: '#27bac2',
                        primary: '#27bac2',
                    },
                })}
                options={options}
                name={name}
                closeMenuOnSelect={closeMenuOnSelect}
                isMulti={isMulti}
                isDisabled={disabled}
                className={classNames(styles.select, className, {[styles.hasError]: !!error})}
                value={value}
                onChange={onChange}
                isSearchable={isSearchable}
            ></Select>
            {!!error && <p className={styles.errorMessage}>{error}</p>}
        </div>
    );
}
