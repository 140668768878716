import React from 'react';
import styles from './Skeleton.module.scss';

interface Props {
  children: React.ReactNode;
  isLoading: boolean;
}

export default function Skeleton({ children, isLoading }: Props): React.ReactElement {
  return (
    <>
      {children}
      {isLoading && <div className={styles.root}></div>}
    </>
  );
}
