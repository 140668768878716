import moment from "moment-timezone";
import React, {useEffect, useState} from 'react';
import {
    duplicateRewardData, sendRewardData,
    setIsRewardCreated, setRewardConditionsFieldValue, setRewardSettingsFieldValue
} from "../../../../state/campaign/campaignSlice";
import PartnerPortal from "../PartnerPortal/PartnerPortal";
import VoucherCodesDropzone from "../VoucherCodesDropzone/VoucherCodesDropzone";
import VoucherCodesManualInput from "../VoucherCodesManualInput/VoucherCodesManualInput";

import styles from './CampaignRewards.module.scss';
import Tabs from 'components/Tabs/Tabs';
import Tab from 'components/Tabs/Tab';
import RewardSettings from '../RewardSettings/RewardSettings';
import RewardConditions from '../RewardConditions/RewardConditions';
import RewardLanguages from '../RewardLanguages/RewardLanguages';
import {RootState} from 'app/store';
import {useDispatch, useSelector} from 'react-redux';

interface TabGroup {
    name: string;
    group: number[];
    enableAction?: (params?: any) => void;
    enableActionLabel?: string;
    action?: (params?: any) => void;
    actionLabel?: string;
}

export default function CampaignRewards(): React.ReactElement {
    const [tabIndex, setTabIndex] = useState(0);
    const dispatch = useDispatch();

    const state = useSelector<RootState, any | null>((state) => state);
    const campaign = useSelector<RootState, any | null>((state) => state.campaign.campaign);
    const reward_id = useSelector<RootState, string | null>((state) => state.campaign.rewardId);

    const values = useSelector<RootState, { [key: string]: string | number }>(
        (state) => state.campaign.rewardsSettings.values
    );
    const rewardId = useSelector<RootState, string | null>((state) => state.campaign.rewardId);
    const [enablePartnerPortalTab, setEnablePartnerPortalTab] = useState<boolean>(false);
    const [isEnabledReward, setIsEnabledReward] = useState<boolean>(false);
    const dispatchValueChange = (name: string, value: string) => {
        dispatch(
            setRewardSettingsFieldValue({
                field: name,
                value,
            })
        );
    };

    const dispatchConditionValueChange = (name: string, value: string | string[] | null) =>
        dispatch(
            setRewardConditionsFieldValue({
                field: name,
                value,
            })
        );

    const duplicateAction = () => {
        dispatchValueChange('reward_name', 'COPY_OF_' + values.reward_name);
        dispatch(setIsRewardCreated(false));
        dispatchConditionValueChange('end_at', moment(moment().subtract(1, 'day').format('yyyy-MM-DD') + 'T00:00+00:00').toISOString());
        dispatch(duplicateRewardData());
    }

    const rewardEnableAction=()=>{
        dispatchValueChange('is_enabled', String(!isEnabledReward));
        dispatch(sendRewardData());
    }

    const campaignTabGroups: TabGroup[] = [
        {
            name: 'Rewards Details',
            group: [0, 1, 2]
        },
        {
            name: 'Voucher Codes',
            group: [3, 4, 5]
        }
    ]

    if (rewardId) {
        campaignTabGroups[0].enableAction = () => rewardEnableAction();
        campaignTabGroups[0].enableActionLabel = `${isEnabledReward?'Enabled':'Disabled'}`;
        campaignTabGroups[0].action = () => duplicateAction();
        campaignTabGroups[0].actionLabel = 'Duplicate Reward';
    }

    useEffect(() => {
        if (campaign && campaign.rewards) {
            const currentRewardSelected = campaign.rewards.filter((item: any) => item.reward_id == reward_id);

            if (currentRewardSelected[0] && currentRewardSelected[0].reward_attributes) {
                setIsEnabledReward(currentRewardSelected[0].is_enabled)
                setEnablePartnerPortalTab(Object.keys(currentRewardSelected[0].reward_attributes).includes('en'));
            }
        }
    });

    return (
        <div className={styles.root}>
            <Tabs tabType="button"
                  tabsGroup={campaignTabGroups}
                  selectedIndex={tabIndex}
                  onSelect={(i) => {
                      setTabIndex(i);
                  }}>
                <Tab name="Rewards Settings">
                    <RewardSettings></RewardSettings>
                </Tab>
                <Tab disabled={!reward_id} name="Conditions">
                    <RewardConditions></RewardConditions>
                </Tab>
                <Tab disabled={!reward_id} name="Messages">
                    <RewardLanguages></RewardLanguages>
                </Tab>
                <Tab disabled={!reward_id} name="Add manually">
                    <VoucherCodesManualInput></VoucherCodesManualInput>
                </Tab>
                <Tab disabled={!reward_id} name="File upload">
                    <VoucherCodesDropzone></VoucherCodesDropzone>
                </Tab>
                <Tab name="Add via Partner Portal"
                     disabledMessage={"Please ensure English Language message is added."}
                     disabled={!enablePartnerPortalTab}>
                    <PartnerPortal></PartnerPortal>
                </Tab>
            </Tabs>
        </div>
    );
}
