import React, {useState, useEffect, memo} from 'react';
import classNames from 'classnames';
import styles from './ImageUpload.module.scss';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import ImageZoomPreview from 'components/ImageZoomPreview/ImageZoomPreview';

import {useDropzone} from 'react-dropzone';
// import {uploadImage} from 'api/campaignApi';

const NO_IMAGE_TEXT = 'No Image';
const INVALID_IMAGE_TEXT = 'Cannot display image';

interface Props {
    imageFieldLabel?: string;
    imageUrl: string;
    onImageSubmit: (image: string) => void;
    showPreview?: boolean;
    error?: string;
    verticalLayout?: boolean;
    applyOnUrlChange?: boolean;
    resize?: string;
}

const calculateAspectRatio = (dimension: { height: number, width: number }): number => {
    return dimension.height / dimension.width;
}

function ImageUpload({
                         imageFieldLabel,
                         imageUrl,
                         onImageSubmit,
                         error,
                         showPreview = false,
                         verticalLayout = false,
                         applyOnUrlChange = false,
                         resize = ''
                     }: Props): React.ReactElement {
    const [urlInputValue, setUrlInputValue] = useState('');
    const [isImageInvalid, setIsImageInvalid] = useState(false);
    const [file, setFile] = useState<File | null>(null);

    const [imageSize, setImageSize] = useState<{
        width: number,
        height: number,
        aspectRatio: number,
        orientation: string
    }>({width: 0, height: 0, aspectRatio: 0, orientation: 'portrait'});
    const imageAspectRatio = calculateAspectRatio(imageSize);
    const parseDimensions = (dimensionsString: string) => {
        const [widthStr, heightStr] = dimensionsString.split('x');
        const width = parseInt(widthStr, 10);
        const height = parseInt(heightStr, 10);

        return {
            width,
            height
        };
    }

    const recAspectRatio = parseDimensions(resize)
        ? calculateAspectRatio(parseDimensions(resize))
        : 0;
    const isDeviationLarge = !!urlInputValue.length && recAspectRatio > 0
        && Math.abs(recAspectRatio - imageAspectRatio) > 0.07;

    useEffect(() => {
        const imgElement = document.createElement("img");
        imgElement.onload = () => {
            let orientation = 'portrait';
            if (imgElement.width > imgElement.height) {
                orientation = 'landscape';
            }
            setImageSize({
                width: imgElement.width,
                height: imgElement.height,
                aspectRatio: imgElement.width / imgElement.height,
                orientation: orientation
            });
        };
        imgElement.src = imageUrl;
    }, [imageUrl]);

    useEffect(() => {
        if (imageUrl) {
            setUrlInputValue(imageUrl);
        }
    }, [imageUrl]);

    useEffect(() => {
        setIsImageInvalid(false);
    }, [onImageSubmit]);

    // const handleImageUpload = async () => {
    //     uploadImage(dataToSend)
    // };

    return (
        <div className={styles.root}>
            <div className={classNames(styles.imageBoxContainer, {[styles.vertical]: verticalLayout})}>
                {imageUrl && !isImageInvalid ? (
                    showPreview ? (
                        <ImageZoomPreview
                            onError={() => setIsImageInvalid(true)}
                            imageUrl={imageUrl}></ImageZoomPreview>
                    ) : (
                        <img
                            src={imageUrl}
                            className={styles.imageBox}
                            onError={() => setIsImageInvalid(true)}
                            alt="New Campaign Image"
                        />
                    )
                ) : (
                    <div className={classNames(styles.imageBox, styles.imagePlaceholder)}>
                        <p>{isImageInvalid ? INVALID_IMAGE_TEXT : NO_IMAGE_TEXT}</p>

                        {resize && (
                            <p>{parseDimensions(resize)?.width} x {parseDimensions(resize)?.height}</p>)
                        }
                    </div>
                )}
            </div>
            <div className={styles.inputContainer}>
                <label className={styles.inputLabel}>{imageFieldLabel || 'Image URL'}</label>
                <div className={classNames(styles.inputHolder, {[styles.vertical]: verticalLayout})}>
                    <Input
                        className={styles.imgUrlInput}
                        type="text"
                        error={error}
                        value={urlInputValue}
                        onChange={(e) => {
                            if (applyOnUrlChange) {
                                onImageSubmit(e.target.value);
                            }
                            setUrlInputValue(e.target.value);
                        }}
                    ></Input>
                    {!applyOnUrlChange && (
                        <Button size="large" onClick={() => onImageSubmit(urlInputValue)}>
                            Apply
                        </Button>
                    )}
                </div>

                {resize && isDeviationLarge && (
                    <div className={styles.aspectRatioNotificationWrapper}>
                        <p> The image size does not match the email template. </p>
                        <p> Recommended size: {resize}</p>
                    </div>
                )}
            </div>
        </div>
    );
}

function areEqual(prevProps: Props, nextProps: Props) {
    return (
        prevProps.imageUrl === nextProps.imageUrl &&
        prevProps.error === nextProps.error &&
        nextProps.onImageSubmit === prevProps.onImageSubmit
    );
}

export default memo(ImageUpload, areEqual);
