import authApiClient from '../../app/apiClient/authApiClient';
import config from '../../env-config';
import cookie from 'js-cookie';
import {
    GRANT_TYPE,
    ORG_ID_COOKIE,
    SCOPE,
    USER_REFRESH_TOKEN_COOKIE,
    USER_TOKEN_COOKIE
} from '../../app/constants/oauthConstants';
import {serializeObject} from '../../app/utils/helpers';
import axios from 'axios';

export const fetchOrganisations = (): any => {
    return authApiClient
        .get('/v1/auth/organisations', {params: {page: 1, page_size: 100000}})
        .then(({data}) => data);
};
export const fetchToken = (): any => {
    const refreshToken = cookie.get(USER_REFRESH_TOKEN_COOKIE);
    const organisationId = cookie.get(ORG_ID_COOKIE);
    const formData = serializeObject({
        refresh_token: refreshToken,
        grant_type: GRANT_TYPE,
        client_id: config.client_id,
        redirect_uri: config.redirect_uri,
        scope: SCOPE,
        organisation_id: organisationId,
    });

    return axios
        .post(`${config.services.api}/oauth/token`, formData, {
            headers: {'content-type': 'application/x-www-form-urlencoded'},
        })
        .then(({data}) => {
            cookie.set(USER_TOKEN_COOKIE, data.access_token, {
                domain: config.appDomain,
                secure: true,
                sameSite: 'strict',
            });
            cookie.set(USER_REFRESH_TOKEN_COOKIE, data.refresh_token, {
                domain: config.appDomain,
                secure: true,
                sameSite: 'strict',
            });
        })
        .catch((err) => {
           return err
        })
};
