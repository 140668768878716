import { createSlice, nanoid } from '@reduxjs/toolkit';
import { Notification } from 'app/interfaces/notification';

interface NotificationsSlice {
  notifications: Notification[];
}

const initialState = {
  notifications: [],
} as NotificationsSlice;

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    showNotification: (state, action) => {
      state.notifications.push({ text: action.payload, id: nanoid() });
    },
    hideNotification: (state, action) => {
      state.notifications = state.notifications.filter(({ id }) => id !== action.payload);
    },
  },
});

export const { showNotification, hideNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;
