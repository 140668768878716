import React from 'react';
import truncate from 'lodash/truncate'
import Tooltip from "../../../../../components/Tooltip/Tooltip";
import styles from './TableBody.module.scss';

interface Props {
    rows: React.ReactNode[][];
}


export default function TableBody({rows}: Props): React.ReactElement {
    return (
        <tbody className={styles.root}>
        {rows.map((cols, i) => (
            <tr className={styles.tableRow} key={i}>
                {cols.map((col, i) => {
                    return (
                        <td key={i} className={styles.tableCell}>
                            {(typeof col === "string" && col.length > 25) ? (
                                <><Tooltip tooltip={col}
                                           position="top">
                                    {truncate(col.toString(), {
                                        'length': 25,
                                        'separator': ' '
                                    })}
                                </Tooltip></>
                            ) : (<>{col}</>)}
                        </td>
                    )
                })}
            </tr>
        ))}
        {rows.length === 0 && <tr className={styles.emptyTableRow}></tr>}
        </tbody>
    );
}
