import { XelacoreModuleDefinition } from 'interfaces/xelacoreModuleDefinition';
import { xelacoreModuleDefinitions } from 'constants/xelacoreModules';
import { XelacoreModule } from 'enums/xelacoreModule';
import Organisation from 'app/interfaces/organisation';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';

export const useXelacoreModules = (): XelacoreModuleDefinition[] => {
  const orgId = useSelector<RootState, string | null>((state) => state.auth.orgId);
  const organisations = useSelector<RootState, Organisation[]>((state) => state.auth.organisations);

  const currentOrganisation = organisations.find((org) => org.organisation_id === orgId);

  const avaialbleModules: XelacoreModule[] = currentOrganisation?.modules || [];
     const mappedAvailableModules = avaialbleModules.filter((module) =>
         xelacoreModuleDefinitions.has(module) && module !== 'reach'
     );
  const availableModulesDefinitions = mappedAvailableModules.map(
    (module) => xelacoreModuleDefinitions.get(module) || { name: '', icon: '', link: '' }
  );
  return availableModulesDefinitions;
};
