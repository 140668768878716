export const config = {
  services: {
    login: 'https://login.testing.xelacore.io',
    api: 'https://api.testing.xelacore.io',
    reach: 'https://reach.testing.xelacore.io',
    account: 'https://account.testing.xelacore.io',
    reg: 'https://testing.xelacore.io',
    plm: 'https://testing.app.xelacore.com',
    cam: 'https://dev.concepts.xelacore.io',
  },
  appDomain: '.xelacore.io',
  baseUrl: 'https://api.testing.xelacore.io',
  client_id: 'f21ce494-0385-4c2e-9175-96f15344aab7',
  redirect_uri: 'https://testing.xelacore.io',
};

export default config;
