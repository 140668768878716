import Skeleton from 'app/components/Skeleton/Skeleton';
import {RootState} from 'app/store';
import Tab from 'components/Tabs/Tab';
import Tabs from 'components/Tabs/Tabs';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {addPartner, setTabTouched} from "../../../../state/campaign/campaignSlice";
import CampaignPartners from "../../../Campaign/components/CampaignPartners/CampaignPartners";
import CampaignMetricsTable from '../CampaignMetricsTable/CampaignMetricsTable';
import CampaignRewards from '../CampaignRewards/CampaignRewards';
import styles from './CampaignDetailsContent.module.scss';

export default function CampaignDetailsContent(): React.ReactElement {
    const partners = useSelector<RootState, any[]>((state) => (state.campaign.campaign || {}).partners);
    const dispatch = useDispatch();
    const [disabledRewardTab, setDisabledRewardTab] = useState(true);
    const [disabledMetricsTab, setDisabledMetricsTab] = useState(true);
    const [tabIndex, setTabIndex] = useState(1);
    const touched = useSelector<RootState, boolean>((state) => state.campaign.tabTouched);
    const isCampaignLoading = useSelector<RootState, boolean>((state) => state.campaign.isCampaignLoading);
    const isPartnersLoading = useSelector<RootState, boolean>((state) => state.campaign.isPartnersLoading);


    useEffect(() => {
        if (!isPartnersLoading && partners) {
            setTimeout(()=>{
                const validPartner = (partners &&  partners.length > 0) &&
                    (partners[partners.length-1].name && partners[partners.length-1].name.length > 0 ) &&
                    (partners[partners.length-1].created_at && partners[partners.length-1].created_at.length > 0);
                setDisabledRewardTab(!validPartner);
                setDisabledMetricsTab(!validPartner);
                if (!touched && validPartner) {
                    setTabIndex(1 );
                    dispatch(setTabTouched(true));
                }else if (!validPartner){
                    setTabIndex(0 );
                }
            },333);
        }
    });

    return (
        <Tabs selectedIndex={tabIndex}
              onSelect={(i) => {
                  dispatch(setTabTouched(true));
                  setTabIndex(i);
              }}>
            <Tab name="Campaign Partners">
                <div className={styles.contentHolder}>
                    <CampaignPartners></CampaignPartners>
                </div>
            </Tab>
            <Tab name="Campaign Rewards" disabled={disabledRewardTab} disabledMessage={'Add at least 1 partner'}>
                <div className={styles.campaignRewardsHolder}>
                    <Skeleton isLoading={isCampaignLoading}>{!isCampaignLoading &&
                        <CampaignRewards></CampaignRewards>}</Skeleton>
                </div>
            </Tab>
            <Tab name="Campaign Metrics" disabled={disabledMetricsTab} disabledMessage={'Add at least 1 partner'}>
                <div className={styles.campaignRewardsHolder}>
                    <Skeleton isLoading={isCampaignLoading}>
                        {!isCampaignLoading && <CampaignMetricsTable></CampaignMetricsTable>}
                    </Skeleton>
                </div>
            </Tab>
        </Tabs>
    );
}
