import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import authReducer from '../features/auth/authSlice';
import campaignsReducer from '../state/campaigns/CampaignsSlice';
import campaignReducer from '../state/campaign/campaignSlice';
import campaignDetailsPageReducer from '../state/ui/campaignDetails/campaignDetailsPageSlice';
import notificationsReducer from 'state/ui/notifications/notificationsSlice';

declare let process: { env: { REACT_APP_STAGE: any } };

export const store = configureStore({
  reducer: {
    auth: authReducer,
    campaigns: campaignsReducer,
    campaign: campaignReducer,
    campaignDetailsPage: campaignDetailsPageReducer,
    notifications: notificationsReducer,
    campaignProperties:campaignReducer
  },
  middleware: (getDefaultMiddleware) =>
    process.env.REACT_APP_STAGE === 'development' ? getDefaultMiddleware().concat(logger) : getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
