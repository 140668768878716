import React, {createRef, MutableRefObject, ReactElement, useEffect, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {ReactComponent as DuplicateIcon} from "../../static/icons/duplicate.svg";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import Tooltip from "../Tooltip/Tooltip";
import styles from './Tabs.module.scss';
import classNames from 'classnames';

interface Props {
    children: ReactElement[] | ReactElement;
    selectedIndex: number;
    tabType?: 'button' | 'default';
    onSelect: (i: number) => void;
    tabsGroup?: TabGroup[];
}

interface TabGroup {
    name: string;
    group: number[];
    enableAction?: (params?: any) => void;
    enableActionLabel?: string;
    action?: (params?: any) => void;
    actionLabel?: string;
}

const defaultSliderParameters = {
    width: 0,
    left: 0,
};

export default function Tabs({
                                 children,
                                 tabType = 'default',
                                 selectedIndex,
                                 tabsGroup = [],
                                 onSelect
                             }: Props): ReactElement {
    const tabs = React.Children.toArray(children) as ReactElement[];
    const displayedTab: ReactElement = tabs[selectedIndex];
    const [buttonRefs, setButtonRefs]: [MutableRefObject<HTMLButtonElement>[], any] = useState([]);
    const [sliderParameters, setSliderParameters] = useState(defaultSliderParameters);
    const containerRef = useRef<HTMLDivElement>(null);
    const isCampaignLoading = useSelector<RootState, boolean>((state) => state.campaign.isCampaignLoading) || false;


    useEffect(() => {
        setButtonRefs((elRefs: MutableRefObject<HTMLButtonElement>[]) => tabs.map((_, i) => elRefs[i] || createRef()));
    }, [children]);

    useEffect(() => {
        if (tabsGroup.length < 1 && buttonRefs[selectedIndex] && containerRef.current) {
            const currentTabButton = buttonRefs[selectedIndex].current;
            const {width, left} = currentTabButton.getBoundingClientRect();
            const {left: containerLeft} = containerRef.current.getBoundingClientRect();
            setSliderParameters({width, left: left - containerLeft});
        }
    }, [selectedIndex, buttonRefs]);

    return (
        <div className={classNames(styles.root, tabsGroup.length > 0 ? styles.groupTabs : null)} ref={containerRef}>
            {tabsGroup.length > 0 && (
                <div className={styles.tabGroupHeaders}>
                    {tabsGroup.map(group => (
                        <div className={styles.tabGroupWrap}>

                            <div className={styles.tabGroupTitleWrap}>
                                <h3>{group.name}</h3>

                                {(group.enableAction && !isCampaignLoading) && (
                                    <a>
                                        <Tooltip tooltip={group.enableActionLabel}>
                                            <ConfirmModal confirmAction={group.enableAction}
                                                          modalTitle="Rewards Details"
                                                          modalContent={`Are you sure you want to ${group.enableActionLabel==='Enabled'?'Disable':'Enable'} reward?`}
                                                          confirmLabel={`${group.enableActionLabel==='Enabled'?'Disable':'Enable'}`}
                                                          cancelLabel="Cancel">

                                                <span
                                                      className={classNames({
                                                          [styles.fakeSwitcher]:true,
                                                          [styles.fakeSwitcherEnabled]: group.enableActionLabel==='Enabled',
                                                      })}
                                                ></span>
                                            </ConfirmModal>

                                        </Tooltip>
                                    </a>
                                )}
                                {(group.actionLabel && !isCampaignLoading) && (
                                    <a>
                                        <Tooltip tooltip={group.actionLabel}>
                                            <ConfirmModal confirmAction={group.action}
                                                          modalTitle="Duplicate Rewards Details"
                                                          modalContent="Are you sure you want to duplicate the campaign rewards?"
                                                          confirmLabel="Duplicate"
                                                          cancelLabel="Cancel">
                                                <DuplicateIcon height={20}></DuplicateIcon>
                                            </ConfirmModal>

                                        </Tooltip>
                                    </a>
                                )}
                            </div>
                            <div className={styles.tabGroupActionWrap}>
                                {group.group.map(tabItem => (
                                        <Tooltip
                                            tooltip={tabs[tabItem].props.disabled ? tabs[tabItem].props.disabledMessage : null}>
                                            <button
                                                className={classNames({
                                                    [styles.tabDefault]: tabType === 'default',
                                                    [styles.tabButton]: tabType === 'button',
                                                    [styles.active]: displayedTab.props.name === tabs[tabItem].props.name,
                                                })}
                                                disabled={tabs[tabItem].props.disabled}
                                                onClick={() => {
                                                    if (!isCampaignLoading) {
                                                        onSelect(tabItem)
                                                    }
                                                }}
                                                key={tabItem}
                                                ref={buttonRefs[tabItem]}
                                            >
                                                {tabs[tabItem].props.name}
                                            </button>
                                        </Tooltip>
                                    )
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {tabsGroup.length < 1 && (
                <div className={styles.tabHeaders}>

                    {tabs.map((tab, i) => (
                        <Tooltip tooltip={tab.props.disabled ? tab.props.disabledMessage : null}>
                            <button
                                className={classNames({
                                    [styles.tabDefault]: tabType === 'default',
                                    [styles.tabButton]: tabType === 'button',
                                    [styles.active]: displayedTab.props.name === tab.props.name,
                                })}
                                disabled={tab.props.disabled}
                                onClick={() => onSelect(i)}
                                key={i}
                                ref={buttonRefs[i]}
                            >
                                {tab.props.name}
                            </button>
                        </Tooltip>

                    ))}
                    {tabType === 'default' && (
                        <div className={styles.slider}
                             style={{left: sliderParameters.left, width: sliderParameters.width}}></div>
                    )}
                </div>
            )
            }
            <div className={styles.tabContent}>{displayedTab}</div>
        </div>
    );
}
