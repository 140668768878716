import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as CaretIconDown } from 'static/icons/caret-down.svg';
import { ReactComponent as FilterIcon } from 'static/icons/filter.svg';
import { ReactComponent as FilterIconSolid } from 'static/icons/filter-solid.svg';

import styles from './TableHeaderCell.module.scss';
import classNames from 'classnames';
import ColumnFilter from '../FIlterPanel/ColumnFilter';
import { Filter } from '../../tableInterfaces';
import { createPortal } from 'react-dom';

interface Props {
  header: any;
  sort: any;
  onSort: any;
  filter: Filter;
  onFilter?: any;
}

const unsortedBlock = (
  <div className={styles.unsortedBlock}>
    <CaretIconDown className={classNames(styles.sortArrow, styles.up, styles.unsortedArrow)}></CaretIconDown>
    <CaretIconDown className={classNames(styles.sortArrow, styles.unsortedArrow)}></CaretIconDown>
  </div>
);

export default function TableHeaderCell({
  header: { field, displayName, filterType, filterOptions },
  sort,
  onSort,
  filter,
  onFilter,
}: Props): React.ReactElement {
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
  const filterPanelRef = useRef<HTMLDivElement>(null);
  const filterContainerRef = useRef<HTMLDivElement>(null);

  const clickHandler = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!filterPanelRef.current?.contains(target)) {
      closePanel();
    }
  };

  const enterKeyPressedhandler = (e: KeyboardEvent) => {
    if (e.keyCode === 13) {
      closePanel();
    }
  };

  useEffect(() => {
    document.addEventListener('keypress', enterKeyPressedhandler);
    return () => document.removeEventListener('keypress', enterKeyPressedhandler);
  });

  const closePanel = () => {
    document.removeEventListener('mousedown', clickHandler);
    document.removeEventListener('keypress', enterKeyPressedhandler);
    setIsFilterPanelOpen(false);
  };

  const handleFilterIconClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setIsFilterPanelOpen((isOpen) => !isOpen);
    document.addEventListener('keypress', enterKeyPressedhandler);
    document.addEventListener('mousedown', clickHandler);
  };

  useEffect(() => {
    return closePanel;
  }, []);

  const isFilterSet = filter && (Array.isArray(filter.value) ? filter.value && filter.value.length : filter.value);

  return (
    <th className={styles.root} key={displayName}>
      <div className={styles.cellContent}>
        {filterType && (
          <div ref={filterContainerRef} className={classNames(styles.iconHolder, styles.filterHolder)}>
            <div onClick={(e) => handleFilterIconClick(e)}>
              {isFilterSet ? (
                <FilterIconSolid className={classNames(styles.filterIcon)}></FilterIconSolid>
              ) : (
                <FilterIcon className={classNames(styles.filterIcon)}></FilterIcon>
              )}
            </div>
            {isFilterPanelOpen && (
              <>
                {createPortal(
                  <ColumnFilter
                    ref={filterPanelRef}
                    onChange={(val) => onFilter({ field, value: { ...val, type: filterType } })}
                    filter={filter}
                    type={filterType}
                    filterOptions={filterOptions}
                    position={{
                      top: filterContainerRef.current?.getBoundingClientRect().y || 0,
                      left: filterContainerRef.current?.getBoundingClientRect().x || 0,
                    }}
                  ></ColumnFilter>,
                  filterPanelRef.current?.closest('.table-root') || document.body
                )}
                {createPortal(
                  <div className={styles.overlay}></div>,
                  filterPanelRef.current?.closest('tr') || document.body
                )}
              </>
            )}
          </div>
        )}
        <p>{displayName}</p>
        {field && (
          <div className={classNames(styles.iconHolder, styles.sortHolder)} onClick={() => onSort(field)}>
            {sort.field !== field ? (
              unsortedBlock
            ) : (
              <CaretIconDown
                className={classNames(styles.sortArrow, {
                  [styles.up]: sort.direction === 'ASC',
                  [styles.down]: sort.direction === 'DESC',
                })}
              ></CaretIconDown>
            )}
          </div>
        )}
      </div>
    </th>
  );
}
