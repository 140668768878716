import classNames from 'classnames';
import React from 'react';
import TableHeaderCell from '../TableHeaderCell/TableHeaderCell';

import styles from './TableHeader.module.scss';

interface Props {
  headers: any[];
  className?: string;
  filters: any;
  sort: any;
  onSort: any;
  onFilter?: (field: string, value: any) => void;
}

export default function TableHeader({
  headers,
  sort,
  className,
  filters = {},
  onSort,
  onFilter,
}: Props): React.ReactElement {
  return (
    <thead className={classNames(styles.root, className)}>
      <tr>
        {headers.map((header,index) => (
          <TableHeaderCell
            key={`${header.field || header.displayName}${index}`}
            header={header}
            sort={sort}
            filter={filters[header.field]}
            onSort={onSort}
            onFilter={onFilter}
          ></TableHeaderCell>
        ))}
      </tr>
    </thead>
  );
}
