import React from 'react';
import classnames from 'classnames';
import {Link} from 'react-router-dom';

import styles from './Button.module.scss';
import classNames from 'classnames';

const buttonTypeMap = {
    primary: styles.primary,
    action: styles.action,
    text: styles.text,
    danger: styles.danger,
    secondary: styles.secondary,
};

const buttonSizeMap = {
    large: styles.large,
    small: styles.small,
    medium: styles.medium,
};

interface Props {
    type?: 'primary' | 'action' | 'text' | 'danger' | 'secondary';
    size?: 'large' | 'small' | 'medium';
    to?: string;
    onClick?: () => void;
    iconLeft?: React.ReactNode;
    iconRight?: React.ReactNode;
    children: React.ReactNode;
    download?: boolean;
    disabled?: boolean;
    id?: string;
    className?: string;
    submit?: boolean;
}

export default function Button({
                                   type = 'primary',
                                   size = 'medium',
                                   to = '',
                                   id,
                                   onClick,
                                   children,
                                   disabled,
                                   iconLeft,
                                   iconRight,
                                   className,
                                   download,
                                   submit = false,
                               }: Props): React.ReactElement {
    const content = (
        <React.Fragment>
            {iconLeft !== undefined && <div className={classNames(styles.iconHolder, styles.left)}>{iconLeft}</div>}
            <span>{children}</span>
            {iconRight !== undefined && <div className={classNames(styles.iconHolder, styles.right)}>{iconRight}</div>}
        </React.Fragment>
    );

    const link = download ? (
        <a
            className={classnames(
                styles.root,
                {
                    [buttonTypeMap[type]]: type,
                    [buttonSizeMap[size]]: size,
                    [styles.disabled]: disabled,
                },
                className
            )}
            id={id}
            download
            href={to}
        >
            {content}
        </a>
    ) : (
        <Link
            className={classnames(
                styles.root,
                {
                    [buttonTypeMap[type]]: type,
                    [buttonSizeMap[size]]: size,
                    [styles.disabled]: disabled,
                },
                className
            )}
            id={id}
            to={to}
            onClick={onClick}
        >
            {content}
        </Link>
    );

    const button = (
        <button
            className={classnames(
                styles.root,
                {
                    [buttonTypeMap[type]]: type,
                    [buttonSizeMap[size]]: size,
                    [styles.disabled]: disabled,
                },
                className
            )}
            id={id}
            onClick={onClick}
            disabled={!!disabled}
            type={submit ? 'submit' : 'button'}
        >
            {content}
        </button>
    );
    return to ? link : button;
}
