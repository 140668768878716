import classNames from "classnames";
import React from 'react';
import styles from './Tooltip.module.scss';

interface Props {
  children: React.ReactNode;
  tooltip?: React.ReactNode;
  position?:string
}

export default function Tooltip({ children, tooltip = null,position='default' }: Props): React.ReactElement {
  return (
    <div className={styles.root}>
      <div className={styles.trigger}>{children}</div>
      {tooltip && <div
          className={classNames(styles.tooltip, styles[position])}>{tooltip}</div>}
    </div>
  );
}
