import React, { useEffect, useState } from 'react';

import styles from './CampaignContent.module.scss';
import Tabs from 'components/Tabs/Tabs';
import Tab from 'components/Tabs/Tab';
import CampaignRewards from '../CampaignRewards/CampaignRewards';
import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { useHistory } from 'react-router-dom';

export default function CampaignContent(): React.ReactElement {
  const isEditing = useSelector<RootState, boolean>((state) => !!state.campaign.rewardId);
  const [tabIndex, setTabIndex] = useState(0);
  const history = useHistory();
  const partnersName = isEditing ? 'Update Campaign Partners' : 'Create Campaign Partners';
  const rewardsName = isEditing ? 'Update Campaign Rewards' : 'Create Campaign Rewards';

  useEffect(() => {
    const tabIndexFromPath = history.location.pathname.endsWith('editRewards') ? 1 : 0;
    setTabIndex(tabIndexFromPath);
  }, [history]);

  return (
    <div className={styles.root}>
      {/*<Tabs selectedIndex={tabIndex} onSelect={(i) => setTabIndex(i)}>*/}
        {/*<Tab name={rewardsName}>*/}
          <CampaignRewards></CampaignRewards>
        {/*</Tab>*/}
      {/*</Tabs>*/}
    </div>
  );
}
