import React from 'react';
import styles from './Tab.module.scss';

interface Props {
  children: React.ReactNode;
  disabled?: boolean;
  name: string;
  disabledMessage?: string;
}

export default function Tab({ children }: Props): React.ReactElement {
  return <div className={styles.root}>{children}</div>;
}
