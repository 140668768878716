import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from './CampaignDetails.module.scss';
import { getCampaign } from '../../state/campaign/campaignSlice';
import CampaignHeader from './components/CampaignHeader/CampaignHeader';
import CampaignDetailsContent from './components/CampaignDetailsContent/CampaignDetailsContent';
import Button from 'components/Button/Button';
import { RootState } from 'app/store';

export default function CampaignDetails(): React.ReactElement {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const isCampaignNotFound = useSelector<RootState, boolean>((state) => state.campaign.isCampaignNotFound);

  useEffect(() => {
    dispatch(getCampaign(id));
  }, [id]);

  return (
    <div className={styles.root}>
      {isCampaignNotFound ? (
        <div className={styles.errorHolder}>
          <h2 className={styles.heading}>No such campaign was found</h2>
          <Button size="large" type="action" to="/">
            Home
          </Button>
        </div>
      ) : (
        <>
          <div className={styles.campaignHeaderHolder}>
            <CampaignHeader></CampaignHeader>
          </div>
          <div className={styles.campaignContentHolder}>{<CampaignDetailsContent></CampaignDetailsContent>}</div>
        </>
      )}
    </div>
  );
}
