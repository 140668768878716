import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';

import './styles/App.scss';

export default function App(): React.ReactElement {
  return (
    <Router>
      <Routes></Routes>
    </Router>
  );
}
