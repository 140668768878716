import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCampaign, setRewardFormData } from '../../state/campaign/campaignSlice';
import styles from './Campaign.module.scss';
import CampaignHeader from './components/CampaignHeader/CampaignHeader';
import CampaignContent from './components/CampaignContent/CampaignContent';
import { RootState } from 'app/store';
import Button from 'components/Button/Button';

export default function Campaign(): React.ReactElement {
  const { id, reward_id } = useParams<{ id: string; reward_id: string | undefined }>();
  const dispatch = useDispatch();
  const isCampaignNotFound = useSelector<RootState, boolean>((state) => state.campaign.isCampaignNotFound);
  const isCampaignsLoading = useSelector<RootState, boolean>((state) => state.campaign.isCampaignLoading);

  useEffect(() => {
    dispatch(getCampaign(id));
  }, [id]);

  useEffect(() => {
    if (!isCampaignsLoading) {
      dispatch(setRewardFormData(reward_id));
    }
  }, [isCampaignsLoading, reward_id]);

  return (
    <div className={styles.root}>
      {isCampaignNotFound ? (
        <div className={styles.errorHolder}>
          <h2 className={styles.heading}>No such campaign was found</h2>
          <Button size="large" type="action" to="/">
            Home
          </Button>
        </div>
      ) : (
        <>
          <div className={styles.campaignHeaderHolder}>
            <CampaignHeader></CampaignHeader>
          </div>
          <div className={styles.campaignContentHolder}>
            <CampaignContent></CampaignContent>
          </div>
        </>
      )}
    </div>
  );
}
