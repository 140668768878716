import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './CampaignsList.module.scss';
import CampaignCard from '../CampaignCard/CampaignCard';
import { RootState } from 'app/store';

export default function CampaignsList(): React.ReactElement {
  const campaigns = useSelector<RootState, any[]>((state) => state.campaigns.campaigns);

  return (
    <div className={styles.root}>
      <h1 className={styles.heading}>Active Campaigns</h1>
      <div className={styles.campaignsList}>
        {campaigns.map((campaign) => (
          <CampaignCard key={campaign.campaign_name} campaign={campaign}></CampaignCard>
        ))}
      </div>
    </div>
  );
}

CampaignsList.propTypes = {
  campaigns: PropTypes.arrayOf(PropTypes.object),
};
