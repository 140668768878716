import React from 'react';
import styles from './TablePagination.module.scss';
import classNames from 'classnames';
import TableRowsNumber from './components/TableRowsNumber/TableRowsNumber';

const MAX_VISIBLE_PAGES = 7;

interface Props {
    page: number;
    rowsPerPage: number;
    totalRows: number;
    onPageSelected: any;
    onRowsNumberSelected: any;
    rowsNumbers: any;
}

function TablePagination({
    page,
    rowsPerPage,
    totalRows,
    onPageSelected,
    onRowsNumberSelected,
    rowsNumbers
}: Props): React.ReactElement {
    const totalPages = rowsPerPage > 0 ? Math.ceil(totalRows / rowsPerPage) : 1;

    let startPage = 0;
    let endPage = totalPages;

    if (totalPages <= MAX_VISIBLE_PAGES) {
        startPage = 0;
        endPage = totalPages;
    } else {
        const maxPagesBeforeCurrentPage = Math.floor(MAX_VISIBLE_PAGES / 2);
        const maxPagesAfterCurrentPage = Math.ceil(MAX_VISIBLE_PAGES / 2) - 1;

        if (page <= maxPagesBeforeCurrentPage) {
            startPage = 0;
            endPage = MAX_VISIBLE_PAGES;
        } else if (page + maxPagesAfterCurrentPage >= totalPages) {
            startPage = totalPages - MAX_VISIBLE_PAGES;
            endPage = totalPages;
        } else {
            startPage = page - maxPagesBeforeCurrentPage;
            endPage = page + maxPagesAfterCurrentPage + 1;
        }
    }

    return (
        <div className={styles.root}>
            <div className={styles.pagination}>
                <button onClick={() => onPageSelected(0)} disabled={page === 0}
                        className={classNames(styles.paginationButton)}>
                    First
                </button>

                <button onClick={() => onPageSelected(page - 1)} disabled={page === 0}
                        className={styles.paginationButton}>
                    Previous
                </button>

                <div className={styles.pageNumberButtons}>
                    {Array(endPage - startPage)
                        .fill(0)
                        .map((_el, i) => (
                            <button
                                onClick={() => onPageSelected(startPage + i)}
                                key={startPage + i}
                                className={classNames(styles.paginationButton, styles.numberButton, {
                                            [styles.active]: page === startPage + i,
                                          })}
                            >
                                {startPage + i + 1}
                            </button>
                        ))}
                </div>

                <button
                    onClick={() => onPageSelected(page + 1)}
                    disabled={page === totalPages - 1 || totalPages === 0}
                    className={styles.paginationButton}
                >
                    Next
                </button>

                <button
                    onClick={() => onPageSelected(totalPages - 1)}
                    disabled={page === totalPages - 1 || totalPages === 0}
                    className={styles.paginationButton}
                >
                    Last
                </button>
            </div>

            <TableRowsNumber
                rowsPerPage={rowsPerPage}
                onRowsNumberSelected={onRowsNumberSelected}
                rowsNumbers={rowsNumbers}
            ></TableRowsNumber>
        </div>
    );
}

export default TablePagination;
