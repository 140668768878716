import React from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';

import CampaignManager from './pages/CampaignManager/CampaignManager';
import './styles/App.scss';
import AuthProtectedRoute from './app/components/AuthProtectedRoute/AuthProtectedRoute';
import Campaign from 'pages/Campaign/Campaign';
import CampaignDetails from 'pages/CampaignDetails/CampaignDetails';

export default function Routes(): React.ReactElement {
  const { pathname } = useLocation();

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <AuthProtectedRoute exact path="/">
        <CampaignManager />
      </AuthProtectedRoute>
      <AuthProtectedRoute
        path={[
          '/campaign/:id/createReward',
          '/campaign/:id/edit/:reward_id',
          '/campaign/:id/edit/:reward_id/editRewards',
        ]}
      >
        <Campaign />
      </AuthProtectedRoute>
      <AuthProtectedRoute path="/campaign/:id">
        <CampaignDetails />
      </AuthProtectedRoute>
    </Switch>
  );
}
