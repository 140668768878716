import Skeleton from 'app/components/Skeleton/Skeleton';
import { RootState } from 'app/store';
import Button from 'components/Button/Button';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetRewardFormData } from 'state/campaign/campaignSlice';
import styles from './CampaignHeader.module.scss';
import { ReactComponent as EditIcon} from 'static/icons/edit.svg';

export default function CampaignHeader(): React.ReactElement {
  const campaign = useSelector<RootState, any>((state) => state.campaign.campaign);
  const isCampaignLoading = useSelector<RootState, boolean>((state) => state.campaign.isCampaignLoading);
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div className={styles.root}>
      <Skeleton isLoading={isCampaignLoading}>
        {!isCampaignLoading && (
          <>
            <img src={campaign.campaign_image_url} alt="Campaign Image" className={styles.campaignImg} />
            <h3 className={styles.campaignName}>{campaign.campaign_name}</h3>
            {/*<Button*/}
            {/*  size="large"*/}
            {/*  type="action"*/}
            {/*  iconLeft={<EditIcon height={16}></EditIcon>}*/}
            {/*  onClick={() => {*/}
            {/*    dispatch(resetRewardFormData());*/}
            {/*    history.push(`/campaign/${campaign.campaign_id}/createReward`);*/}
            {/*  }}*/}
            {/*  className={styles.editButton}*/}
            {/*>*/}
            {/*  Edit campaign*/}
            {/*</Button>*/}
          </>
        )}
      </Skeleton>
    </div>
  );
}
