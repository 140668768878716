import React from 'react';
import { Route } from 'react-router-dom';

import { useAuth } from '../../hooks/useAuth';
import OrganisationSelect from '../../../pages/OrganisationSelect/OrganisationSelect';
import Layout from '../Layout/Layout';

interface Props {
  children: React.ReactNode;
  path: string | string[];
  exact?: boolean | undefined;
}

export default function AuthProtectedRoute({ children, exact, path }: Props): React.ReactElement {
  const [isAuthenticated, isOrgSelected] = useAuth();
  return isAuthenticated ? (
    isOrgSelected ? (
      <Layout>
        <Route exact={exact} path={path}>
          {children}
        </Route>
      </Layout>
    ) : (
      <OrganisationSelect></OrganisationSelect>
    )
  ) : (
    <></>
  );
}
