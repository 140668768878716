import { Partner } from 'app/interfaces/partner';
import axios from "axios";
import chunk from "lodash/chunk";
import apiClient from '../app/apiClient/apiClient';

interface chunkItem {
    codes: string[] | any;
    win_max: number;
    not_visible_in_email:boolean;
}
export const fetchCampaign = (id: string): any => {
  return apiClient.get(`/v1/redemption-delivery/manage/campaigns/${id}`).then((data) => data);
};

export const fetchPartners = (id: string): any => {
  return apiClient.get(`/v1/redemption-delivery/manage/campaigns/${id}/partners`).then((data) => data);
};

export const patchPartners = (id: string, partners: Partner[]): any => {
  return apiClient.patch(`/v1/redemption-delivery/manage/campaigns/${id}/partners`, { partners }).then((data) => data);
};

export const postPartners = (id: string, partners: Partial<Partner>[]): any => {
  return apiClient.post(`/v1/redemption-delivery/manage/campaigns/${id}/partners`, { partners }).then((data) => data);
};

export const fetchMetrics = (id: string, { from, to }: { from: string | null; to: string | null }): any => {
  return apiClient
    .get(`/v1/redemption-delivery/manage/campaigns/${id}/metrics`, {
      params: {
        'filter[from]': from,
        'filter[to]': to,
      },
    })
    .then((data) => data);
};

export const downloadMetricsCsv = (id: string, { from, to }: { from: string | null; to: string | null }): any => {
  return apiClient
    .get(`/v1/redemption-delivery/manage/campaigns/${id}/metrics`, {
      params: {
        'filter[from]': from,
        'filter[to]': to,
      },
      headers: { Accept: 'text/csv' },
    })
    .then((data) => data.data);
};

export const fetchRewards = (id: string): any => {
  return apiClient.get(`/v1/redemption-delivery/manage/campaigns/${id}/rewards`).then((data) => data);
};

export const fetchCampaigns = (): any => {
  return apiClient.get('/v1/redemption-delivery/manage/campaigns').then((data) => data);
};

export const patchCampaign = ({
  campaign_id,
  campaign_name,
  campaign_image_url,
}: {
  campaign_id: string;
  campaign_name: string;
  campaign_image_url: string;
}): any => {
  return apiClient
    .patch(`/v1/redemption-delivery/manage/campaigns/${campaign_id}`, {
      campaign_name,
      campaign_image_url,
    })
    .then((data) => data);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const postReward = (campaign_id: number, reward: any): any => {
  return apiClient
    .post(`/v1/redemption-delivery/manage/campaigns/${campaign_id}/rewards`, { rewards: [reward] })
    .then((data) => data);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const patchReward = (campaign_id: number, rewardId: string, reward: any): any => {
  return apiClient
    .patch(`/v1/redemption-delivery/manage/campaigns/${campaign_id}/rewards`, {
      rewards: [{ ...reward, reward_id: rewardId }],
    })
    .then((data) => data);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const postCodes = (campaign_id: string, reward_id: string, code: any): any => {
  return apiClient
    .post(`/v1/redemption-delivery/manage/campaigns/${campaign_id}/rewards/${reward_id}/codes`, { ...code })
    .then((data) => data);
};

export const postChunkCodes = (campaign_id: string, reward_id: string, codeData: any,batchSize:number): any => {
    const codeList = codeData.codes || [];
    const chunkCodes = chunk(codeList, batchSize);
    const chunkValues: chunkItem[] = [];
    chunkCodes.forEach((codeChunk) => {
        chunkValues.push({codes: codeChunk, win_max: codeData.win_max,not_visible_in_email:codeData.not_visible_in_email})
    })
    const requestArray  = chunkValues.map(chunkpart=> apiClient
        .post(`/v1/redemption-delivery/manage/campaigns/${campaign_id}/rewards/${reward_id}/codes`, { ...chunkpart }))
    return axios.all(requestArray).then(data=>data)
};


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const generateCodes = (params: any): any => {
  return apiClient
    .post(`/v1/redemption-delivery/generate/codes`, { ...params })
    .then((data) => data);
};
