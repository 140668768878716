import React, {useState} from 'react';
import styles from './ConfirmModal.module.scss';

interface Props {
    children: React.ReactNode;
    confirmAction?: (params?: any) => void;
    modalTitle?:string;
    modalContent?:string;
    confirmLabel?:string;
    cancelLabel?:string;
}

export default function ConfirmModal({children, confirmAction,confirmLabel='confirm',cancelLabel='cancel',modalTitle,modalContent}: Props): React.ReactElement {
    const [openModalState, setOpenModalState] = useState(false);

    const openModal = () => {
        setOpenModalState(true)
    }

    const closeModal = () => {
        setOpenModalState(false)
    }
    const confirmModalAction = () => {
        if (confirmAction) {
            confirmAction()
        }
        closeModal();
    }

    return (
        <div className={styles.root}>
            <div className={styles.trigger}
                 onClick={openModal}>{children}</div>

            {openModalState && (
                <div className={styles.modalWrapper}>
                    <div className={styles.modalBody}>

                        <div className={styles.modalContent}>
                            <h3>{modalTitle}</h3>
                            <p>{modalContent}</p>
                        </div>
                        <div className={styles.modalAction}>
                            <button className={styles.confirmButton} onClick={confirmModalAction}>{confirmLabel}</button>
                            <button className={styles.cancelButton} onClick={closeModal}>{cancelLabel}</button>
                        </div>
                    </div>
                    Modal Here
                </div>
            )}
        </div>
    );
}
